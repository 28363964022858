import { createAsyncThunk } from '@reduxjs/toolkit';
import { editBill, fetchAllBills, fetchBillsByCompany } from '../../../api/bill';
import { IFetchBillParams } from '../../../api/bill/models';
import { errorProcesor } from '../../../utils/errorProcesor';
import { IEditBillAsyncProps } from '../models';

export const fetchBillsByCompanyAsync = createAsyncThunk(
  'customerBill/fetchBills',
  async (params: IFetchBillParams, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchBillsByCompany(params);
      return errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const editBillAsync = createAsyncThunk(
  'adminBill/editBill',
  async ({ token, id, data }: IEditBillAsyncProps, { rejectWithValue, dispatch }) => {
    try {
      const response = await editBill(token, id, data);
      return errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchAllBillsAsync = createAsyncThunk(
  'adminBill/fetchAllBills',
  async ({ token }: { token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchAllBills(token);
      return errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
