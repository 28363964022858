import { CANDIDATE_STAGE, ICandidateJobOffer } from '../../../models/ICandidateJobOffer';

const stageMap = {
  [CANDIDATE_STAGE.EVALUATIONS]: 'evaluation',
  [CANDIDATE_STAGE.OFFERS]: 'offer',
};

export const getCandidatesByStage = (candidates: ICandidateJobOffer[]) => {
  const initialState = {
    evaluation: 0,
    offer: 0,
  };

  const res = candidates.reduce((acc, el) => {
    const stage = stageMap[el.stage];
    if (stage) acc[stage]++;

    return acc;
  }, initialState);

  return res;
};
