import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store/index';
import { toastSuccessMessage } from '../../utils/toast';
import { ICustomerPlanState } from './models';
import {
  createCustomerPlanAsync,
  fetchCustomerPlanAsync,
  fetchPendingCustomerPlanAsync,
  updateCustomerPlanAsync,
} from './thunks';

export const initialState: ICustomerPlanState = {
  customerPlan: null,
  status: 'idle',
  enabled: false,
  isMonthly: false,
};

export const customerPlanSlice = createSlice({
  name: 'customerPlan',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //fetchJobOfferByCompanyAsync
      .addCase(fetchCustomerPlanAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCustomerPlanAsync.rejected, (state) => {
        state.status = 'failed';
        state.customerPlan = null;
      })
      .addCase(fetchCustomerPlanAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.customerPlan = action.payload.data ? action.payload.data : null;
        if (state.customerPlan?.status === 'Habilitado') state.enabled = true;
        if (state.customerPlan?.isMonthly) state.isMonthly = true;
      })

      //createCustomerPlanAsync
      .addCase(createCustomerPlanAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createCustomerPlanAsync.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.status = 'success';
        data.status === 'Habilitado' ? (state.customerPlan = data) : (state.pending = data);
        toastSuccessMessage('El plan ha sido solicitado con éxito');
      })
      .addCase(createCustomerPlanAsync.rejected, (state) => {
        state.status = 'failed';
      })

      //updateCustomerPlanAsync
      .addCase(updateCustomerPlanAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCustomerPlanAsync.fulfilled, (state, action) => {
        state.status = 'success';
        let userAction = 'actualizado';
        const { data } = action.payload;
        if (data._id === state.customerPlan?._id) {
          state.customerPlan = data.status === 'Habilitado' ? data : null;
        }

        if (data._id === state.pending?._id) {
          state.pending = undefined;
          userAction = 'cancelado';
        }

        toastSuccessMessage(`El plan ha sido ${userAction} con éxito`);
      })
      .addCase(updateCustomerPlanAsync.rejected, (state) => {
        state.status = 'failed';
      })

      //fetchPendingCustomerPlanAsync
      .addCase(fetchPendingCustomerPlanAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPendingCustomerPlanAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.pending = action.payload.data;
      })
      .addCase(fetchPendingCustomerPlanAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const getCustomerPlan = (state: RootState) => state.customerPlan;
export const getCustomerPlanStatus = (state: RootState) => state.customerPlan.status;

export default customerPlanSlice.reducer;
