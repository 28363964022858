import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: ${({ theme }) => theme.typography}, 'Segoe UI', Verdana, 'Noto Sans', Ubuntu, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: ${({ theme }) => theme.color.neutral};
  }

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  a {
    width: 100%;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    color: inherit;
    
    &:hover {
      filter: brightness(1.2);
    }
  }

  ul {
    list-style: none;
  }
`;
