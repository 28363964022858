import { ToastOptions, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const options: ToastOptions = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
};

export const toastSuccessMessage = (msg: string) => toast.success(msg, options);
export const toastErrorMessage = (msg: string) => toast.error(msg, options);
export const toastWarningMessage = (msg: string) => toast.warning(msg, options);
export const toastInfoMessage = (msg: string) => toast.info(msg, options);
