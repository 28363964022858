import { ICreateService } from '../../../models/IService';
import { IFetchOptions } from '../../../models/IFetch';
import { API_URL } from '../../../utils/constants/api';
import { getHeaders } from '../../utils';

export default {
  createService: (serviceData: ICreateService, token: string): IFetchOptions => ({
    url: `${API_URL}/services`,
    params: {
      method: 'POST',
      headers: getHeaders(token),
      body: JSON.stringify(serviceData),
    },
  }),

  getService: (serviceId: string, token: string): IFetchOptions => ({
    url: `${API_URL}/services/${serviceId}`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),

  editService: (serviceId: string, updatedData: ICreateService, token: string): IFetchOptions => ({
    url: `${API_URL}/services/${serviceId}`,
    params: {
      method: 'PUT',
      headers: getHeaders(token),
      body: JSON.stringify(updatedData),
    },
  }),

  getServices: (token: string): IFetchOptions => {
    return {
      url: `${API_URL}/services`,
      params: {
        method: 'GET',
        headers: getHeaders(token),
      },
    };
  },

  deleteService: (serviceId: string, token: string): IFetchOptions => ({
    url: `${API_URL}/services/${serviceId}`,
    params: {
      method: 'DELETE',
      headers: getHeaders(token),
    },
  }),
};
