import { IPopUpContent } from '../../../../redux/popUpSlice/models';

export const recoverySuccess: IPopUpContent = {
  icon: 'planeWind',
  title: 'Restablecer mi contraseña',
  message:
    'Si esta dirección de correo electrónico existe en nuestro sistema, recibirás un mail con un link para restablecer tu contraseña.',
  buttons: [
    {
      value: 'Continuar',
      action: 'handleAction',
    },
  ],
};
