import { IFetchOptions } from '../../../models/IFetch';
import { ICreateJobOfferAsyncProps, IEditJobOfferAsyncProps } from '../../../redux/jobOffersSlice/models';
import { API_URL } from '../../../utils/constants/api';
import { getHeaders } from '../../utils';
import { IUpdateJobOfferSuspensionProps } from '../models';

export default {
  getJobOffersByCustomer: (token: string, companyId: string): IFetchOptions => ({
    url: `${API_URL}/job-offers/all/${companyId}`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),
  getJobOfferById: (token: string, id: string): IFetchOptions => ({
    url: `${API_URL}/job-offers/${id}`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),
  createJobOffer: ({ token, data }: ICreateJobOfferAsyncProps): IFetchOptions => ({
    url: `${API_URL}/job-offers`,
    params: {
      method: 'POST',
      headers: getHeaders(token),
      body: JSON.stringify(data),
    },
  }),
  editJobOffer: ({ token, id, data }: IEditJobOfferAsyncProps): IFetchOptions => ({
    url: `${API_URL}/job-offers/${id}`,
    params: {
      method: 'PUT',
      headers: getHeaders(token),
      body: JSON.stringify(data),
    },
  }),

  updateSuspension: ({ token, id, isSuspended }: IUpdateJobOfferSuspensionProps): IFetchOptions => ({
    url: `${API_URL}/job-offers/${id}/suspension `,
    params: {
      method: 'PUT',
      headers: getHeaders(token),
      body: JSON.stringify({ isSuspended }),
    },
  }),
};
