import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store/index';
import { getAdministratorsAsync } from './thunks';
import { IAdministratorState } from './models';

export const initialState: IAdministratorState = {
  administratorList: [],
  status: 'idle',
};

export const administratorSlice = createSlice({
  name: 'comleteWork',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // getAdministratorsAsync
      .addCase(getAdministratorsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAdministratorsAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.administratorList = action.payload.data;
      })
      .addCase(getAdministratorsAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const getAdministratorState = (state: RootState) => state.administrator;

export default administratorSlice.reducer;
