import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IHomeKamState } from './models';
import { fetchSummaryHomeKamAsync } from './thunks';

export const initialState: IHomeKamState = {
  status: 'idle',
};

const homeKamSlice = createSlice({
  name: 'homeKam',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSummaryHomeKamAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSummaryHomeKamAsync.fulfilled, (state, action) => {
        const { candidates, jobOffers, numOfCompanies } = action.payload.data;
        state.status = 'success';
        state.numOfCompanies = numOfCompanies;
        state.jobOffers = jobOffers;
        state.candidates = candidates;
      })
      .addCase(fetchSummaryHomeKamAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const getHomeKamState = (state: RootState) => state.homeKam;

export default homeKamSlice.reducer;
