export const debounce = <T extends (...args: any[]) => ReturnType<T>>(
  cb: T,
  delay = 300
): ((...args: Parameters<T>) => void) => {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;

  return (...args: Parameters<T>): void => {
    const later = () => {
      timeoutId = undefined;
      cb(...args);
    };

    clearTimeout(timeoutId);
    timeoutId = setTimeout(later, delay);
  };
};
