import { ICandidatesRecruiterResponse } from './models';
import URL from './url';

export const getCandidatesRecruiter = async (
  token: string,
  jobOfferId?: string,
  recruiterId?: string
): Promise<ICandidatesRecruiterResponse> => {
  const { url, params } = URL.getCandidatesRecruiter(token, jobOfferId, recruiterId);
  const response = await fetch(url, params);
  return response.json();
};

export const createCandidateRecruiter = async (
  data: FormData,
  token: string
): Promise<ICandidatesRecruiterResponse> => {
  const { url, params } = URL.createCandidateRecruiter(data, token);
  const response = await fetch(url, params);
  return response.json();
};
