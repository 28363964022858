import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createUserByCompany,
  editCompanyUser,
  fetchUsersByCompany,
  getOneCustomerCompanyUser,
} from '../../../api/customerCompanyUsers';
import { ICreateCompanyUserParams, IFetchCustomerCompanyUsersParams } from '../../../api/customerCompanyUsers/models';
import { errorProcesor } from '../../../utils/errorProcesor';

export const fetchUsersByCompanyAsync = createAsyncThunk(
  'customerCompanyUsers/fetchCustomerCompanyUsers',
  async (params: IFetchCustomerCompanyUsersParams, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchUsersByCompany(params);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const createCustomerCompanyUserAsync = createAsyncThunk(
  'customerCompanyUsers/createCustomerCompanyUserAsync',
  async ({ fields, token }: { fields: ICreateCompanyUserParams; token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await createUserByCompany(fields, token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

// Acción asíncrona para editar/actualizar un usuario
export const updateCompanyUserAsync = createAsyncThunk(
  'customerCompanyUsers/updateCompanyUserAsync',
  async (
    { userId, data, token }: { userId: string; data: ICreateCompanyUserParams; token: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await editCompanyUser(userId, data, token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const getOneCustomerCompanyUserAsync = createAsyncThunk(
  'customerCompanyUsers/getOneCustomerCompanyUserAsync',
  async ({ userCompanyId, token }: { userCompanyId: string; token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await getOneCustomerCompanyUser(userCompanyId, token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
