import { createAsyncThunk } from '@reduxjs/toolkit';
import { errorProcesor } from '../../../utils/errorProcesor';
import {
  ICreateOfferByCandidateDataRequest,
  IEditOfferByCandidateDataRequest,
  IFetchOffersByCandidateDataResponse,
} from '../models';
import { createOffer, editOffer, getOffersByCandidate } from '../../../api/candidatesOffers';

export const fetchOffersByCandidateAsync = createAsyncThunk(
  'candidatesOffers/fetchOffersByCandidate',
  async ({ token, candidateId }: IFetchOffersByCandidateDataResponse, { rejectWithValue, dispatch }) => {
    try {
      const response = await getOffersByCandidate(token, candidateId);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const createCandidateOfferAsync = createAsyncThunk(
  'candidatesOffersSlice/createCandidateOfferAsync',
  async ({ data, candidateId, token }: ICreateOfferByCandidateDataRequest, { rejectWithValue, dispatch }) => {
    try {
      const response = await createOffer(data, candidateId, token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const editCandidateOfferAsync = createAsyncThunk(
  'candidatesOffersSlice/editCandidateOfferAsync',
  async ({ data, offerId, candidateId, token }: IEditOfferByCandidateDataRequest, { rejectWithValue, dispatch }) => {
    try {
      const response = await editOffer(data, offerId, candidateId, token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
