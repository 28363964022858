import { IFetchOptions } from '../../../models/IFetch';
import { API_URL } from '../../../utils/constants/api';
import { getHeaders } from '../../utils';
import { ICreateCompanyUserParams, IFetchCustomerCompanyUsersParams } from '../models';

export default {
  fetchUsersByCompany: ({ companyId, token }: IFetchCustomerCompanyUsersParams): IFetchOptions => ({
    url: `${API_URL}/user/company/${companyId}`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),

  createCustomerUserByCompany: (fields: ICreateCompanyUserParams, token: string): IFetchOptions => {
    const role = fields.user?.roles?.[0].toLowerCase() ?? ['customer'];

    return {
      url: `${API_URL}/user/${role}/register`,
      params: {
        method: 'POST',
        headers: getHeaders(token),
        body: JSON.stringify(fields),
      },
    };
  },

  editCompanyUser: (userId: string, updatedData: ICreateCompanyUserParams, token: string): IFetchOptions => ({
    url: `${API_URL}/user/company/${userId}`,
    params: {
      method: 'PUT',
      headers: getHeaders(token),
      body: JSON.stringify(updatedData),
    },
  }),

  getOneCustomerCompanyUser: (userCompanyId: string, token: string): IFetchOptions => ({
    url: `${API_URL}/user/company/one/${userCompanyId}`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),
};
