import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createCustomerPlan,
  fetchCustomerPlan,
  fetchPendingCustomerPlan,
  updateCustomerPlan,
} from '../../../api/customerPlan';
import {
  ICreateCustomerPlanProps,
  IFetchPendingCustomerPlanProps,
  IUpdateCustomerPlanProps,
} from '../../../api/customerPlan/models';
import { errorProcesor } from '../../../utils/errorProcesor';

export const fetchCustomerPlanAsync = createAsyncThunk(
  'customerPlan/fetchByCustomer',
  async ({ token }: { token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchCustomerPlan(token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const createCustomerPlanAsync = createAsyncThunk(
  'customerPlan/create',
  async (params: ICreateCustomerPlanProps, { rejectWithValue, dispatch }) => {
    try {
      const response = await createCustomerPlan(params);
      return errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateCustomerPlanAsync = createAsyncThunk(
  'customerPlan/update',
  async (args: IUpdateCustomerPlanProps, { rejectWithValue, dispatch }) => {
    try {
      const response = await updateCustomerPlan(args);
      return errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchPendingCustomerPlanAsync = createAsyncThunk(
  'customerPlan/pending',
  async (args: IFetchPendingCustomerPlanProps, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchPendingCustomerPlan(args);
      return errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
