import {
  ICreateCompanyUserParams,
  ICustomerCompanyUserListResponse,
  IFetchCustomerCompanyUsersParams,
  IGetCustomerCompanyUsersResponse,
  IGetOneCustomerCompanyUserResponse,
} from './models';
import URL from './url';

export const fetchUsersByCompany = async (
  args: IFetchCustomerCompanyUsersParams
): Promise<IGetCustomerCompanyUsersResponse> => {
  const { url, params } = URL.fetchUsersByCompany(args);
  const response = await fetch(url, params);
  return await response.json();
};

export const createUserByCompany = async (
  fields: ICreateCompanyUserParams,
  token: string
): Promise<ICustomerCompanyUserListResponse> => {
  const { url, params } = URL.createCustomerUserByCompany(fields, token);
  const response = await fetch(url, params);
  return await response.json();
};

export const editCompanyUser = async (
  userId: string,
  updatedData: ICreateCompanyUserParams,
  token: string
): Promise<ICustomerCompanyUserListResponse> => {
  const { url, params } = URL.editCompanyUser(userId, updatedData, token);
  const response = await fetch(url, params);
  return await response.json();
};

export const getOneCustomerCompanyUser = async (
  userCompanyId: string,
  token: string
): Promise<IGetOneCustomerCompanyUserResponse> => {
  const { url, params } = URL.getOneCustomerCompanyUser(userCompanyId, token);
  const response = await fetch(url, params);
  return await response.json();
};
