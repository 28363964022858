import { createSlice } from '@reduxjs/toolkit';
import { ICandidatesRecruiterState } from './models';
import {
  createCandidateRecruiterAsync,
  fetchCandidatesRecruiterAsync,
  fetchCandidatesRecruiterByRecruiterAsync,
} from './thunks';
import { RootState } from '../../store/index';
import { toastSuccessMessage } from '../../utils/toast';

const initialState: ICandidatesRecruiterState = {
  candidatesList: [],
  status: 'idle',
  candidatesListByRecruiter: [],
};

export const candidatesRecruiterSlice = createSlice({
  name: 'candidatesRecruiter',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //fetchCandidatesRecruiterAsync
      .addCase(fetchCandidatesRecruiterAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCandidatesRecruiterAsync.rejected, (state) => {
        state.status = 'failed';
        state.candidatesList = [];
      })
      .addCase(fetchCandidatesRecruiterAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.candidatesList = action.payload.data;
      })

      //fetchCandidatesRecruiterByRecruiterAsync
      .addCase(fetchCandidatesRecruiterByRecruiterAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCandidatesRecruiterByRecruiterAsync.rejected, (state) => {
        state.status = 'failed';
        state.candidatesListByRecruiter = [];
      })
      .addCase(fetchCandidatesRecruiterByRecruiterAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.candidatesListByRecruiter = action.payload.data;
      })

      //createUserAsync
      .addCase(createCandidateRecruiterAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createCandidateRecruiterAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.candidatesList = action.payload.data;
        toastSuccessMessage('Postulante creado con éxito');
      })
      .addCase(createCandidateRecruiterAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const getCandidatesRecruiterList = (state: RootState) => state.candidates.candidatesList;
export const getCandidatesListByRecruiter = (state: RootState) => state.candidates.candidatesListByRecruiter;
export const getCandidatesRecruiterStatus = (state: RootState) => state.candidates.status;

export default candidatesRecruiterSlice.reducer;
