import { createAsyncThunk } from '@reduxjs/toolkit';
import { editUser } from '../../../api/users';
import { errorProcesor } from '../../../utils/errorProcesor';
import { IUserActivation } from '../models';

// Acción asíncrona para desactivar un usuario
export const deactivateUserAsync = createAsyncThunk(
  'user/deactivateUser',
  async ({ userId, data, token }: IUserActivation, { rejectWithValue, dispatch }) => {
    try {
      const response = await editUser(userId, data, token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

// Acción asíncrona para activar un usuario
export const activateUserAsync = createAsyncThunk(
  'user/activateUser',
  async ({ userId, data, token }: IUserActivation, { rejectWithValue, dispatch }) => {
    try {
      const response = await editUser(userId, data, token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
