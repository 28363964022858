import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { toastSuccessMessage } from '../../utils/toast';
import { IUserState } from './models';
import { createUserAsync, editUserAsync, fetchUsersAllAsync, getUserAsync, getUsersAdminAsync } from './thunks';

export const initialState: IUserState = {
  users: [],
  selectedUser: null,
  status: 'idle',
  allUserStatus: 'idle',
  error: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //getAllUsersAsync
      .addCase(getUsersAdminAsync.pending, (state) => {
        state.allUserStatus = 'loading';
      })
      .addCase(getUsersAdminAsync.fulfilled, (state, action) => {
        state.users = action.payload.data;
      })
      .addCase(getUsersAdminAsync.rejected, (state) => {
        state.allUserStatus = 'failed';
      })

      //createUserAsync
      .addCase(createUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createUserAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.users.push(action.payload.data);
        toastSuccessMessage('Usuario creado con éxito');
      })
      .addCase(createUserAsync.rejected, (state) => {
        state.status = 'failed';
      })

      //getUserAsync
      .addCase(getUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getUserAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.selectedUser = action.payload.data;
      })
      .addCase(getUserAsync.rejected, (state) => {
        state.status = 'failed';
      })

      //editUserAsync
      .addCase(editUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editUserAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.selectedUser = null;
        state.users = action.payload.data.list;
        toastSuccessMessage('Usuario editado con éxito');
      })
      .addCase(editUserAsync.rejected, (state) => {
        state.status = 'failed';
      })

      //fetchUsersAllAsync
      .addCase(fetchUsersAllAsync.pending, (state) => {
        state.allUserStatus = 'loading';
      })
      .addCase(fetchUsersAllAsync.fulfilled, (state, action) => {
        state.users = action.payload.data;
      })
      .addCase(fetchUsersAllAsync.rejected, (state) => {
        state.allUserStatus = 'failed';
      });
  },
});

export const getUserListState = (state: RootState) => state.user.users;
export const getUserState = (state: RootState) => state.user;
export const getSelectedUser = (state: RootState) => state.user.selectedUser;
export const getUserStatus = (state: RootState) => state.user.status;

export default userSlice.reducer;
