import { ICreateSkillGroup, IEditSkillGroup } from '../../models/ISkill';
import URL from './url';
import {
  ICustomerOneSkillsGroupsResponse,
  ICustomerSkillsGroupsListResponse,
  ICustomerSkillsGroupsResponse,
  ICustomerSkillsGroupsTemplateResponse,
} from './models';

export const createCustomerSkillsGroup = async (
  skillsGroupData: ICreateSkillGroup,
  token: string
): Promise<ICustomerSkillsGroupsListResponse> => {
  const { url, params } = URL.createCustomerSkillsGroup(skillsGroupData, token);
  const response = await fetch(url, params);
  return await response.json();
};

export const getCustomerSkillGroups = async (
  companyId: string,
  token: string
): Promise<ICustomerSkillsGroupsResponse> => {
  const { url, params } = URL.getCustomerSkillGroups(companyId, token);
  const response = await fetch(url, params);
  return await response.json();
};

export const getOneCustomerSkillGroup = async (
  Id: string,
  token: string
): Promise<ICustomerOneSkillsGroupsResponse> => {
  const { url, params } = URL.getOneCustomerSkillGroup(Id, token);
  const response = await fetch(url, params);
  return await response.json();
};

export const editCustomerSkillGroup = async (
  Id: string,
  updatedData: IEditSkillGroup,
  token: string
): Promise<ICustomerSkillsGroupsListResponse> => {
  const { url, params } = URL.editCustomerSkillGroup(Id, updatedData, token);
  const response = await fetch(url, params);
  return await response.json();
};

export const deleteCustomerSkillGroup = async (Id: string, token: string): Promise<ICustomerSkillsGroupsResponse> => {
  const { url, params } = URL.deleteCustomerSkillGroup(Id, token);
  const response = await fetch(url, params);
  return await response.json();
};

export const getCustomerTemplateSkillGroups = async (
  companyId: string,
  token: string
): Promise<ICustomerSkillsGroupsTemplateResponse> => {
  const { url, params } = URL.getCustomerTemplateSkillGroups(companyId, token);
  const response = await fetch(url, params);
  return await response.json();
};

export const deleteCustomerTemplateSkillGroup = async (
  Id: string,
  token: string
): Promise<ICustomerSkillsGroupsTemplateResponse> => {
  const { url, params } = URL.deleteCustomerTemplateSkillGroup(Id, token);
  const response = await fetch(url, params);
  return await response.json();
};
