import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { initialPages } from '../../utils/constants/pagination';
import { toastSuccessMessage } from '../../utils/toast';
import { ICompanyState } from './models';
import {
  createAdviceAsync,
  createCompanyAsync,
  editCompanyAsync,
  getAllCompaniesAsync,
  getAssignedCompaniesKamAsync,
  getCompaniesAsync,
  getCompanyAsync,
  getCompanyPlanAsync,
  getKAMByCompanyAsync,
} from './thunks';

export const initialState: ICompanyState = {
  companies: [],
  status: 'idle',
  error: null,
  pages: initialPages,
  selectedCompany: null,
  selectedPlan: null,
};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //getCompaniesAsync
      .addCase(getCompaniesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCompaniesAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.companies = action.payload.data.list;
        state.pages = action.payload.data.pages;
      })
      .addCase(getCompaniesAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      //createCompanyAsync
      .addCase(createCompanyAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createCompanyAsync.fulfilled, (state, action) => {
        state.companies = action.payload.data.list;
        state.status = 'success';
        toastSuccessMessage('Compañía creada con éxito');
      })
      .addCase(createCompanyAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      //getCompanyAsync
      .addCase(getCompanyAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCompanyAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.selectedCompany = action.payload.data;
      })
      .addCase(getCompanyAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      //editCompanyAsync
      .addCase(editCompanyAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editCompanyAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.companies = action.payload.data.list;
        toastSuccessMessage('Compañía editada con éxito');
      })
      .addCase(editCompanyAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      //getAllCompaniesAsync
      .addCase(getAllCompaniesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllCompaniesAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.companies = action.payload.data;
      })

      //getCompanyPlanAsync
      .addCase(getCompanyPlanAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCompanyPlanAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.selectedPlan = action.payload.data;
      })
      .addCase(getCompanyPlanAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      //getAssignedCompaniesKAM
      .addCase(getAssignedCompaniesKamAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAssignedCompaniesKamAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.companies = action.payload.data;
      })
      .addCase(getAssignedCompaniesKamAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      //getKAMByCompanyAsync
      .addCase(getKAMByCompanyAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getKAMByCompanyAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.myKAM = action.payload.data;
      })
      .addCase(getKAMByCompanyAsync.rejected, (state) => {
        state.status = 'failed';
      })

      //createAdviceAsync
      .addCase(createAdviceAsync.pending, (state) => {
        state.adviceStatus = 'loading';
      })
      .addCase(createAdviceAsync.fulfilled, (state) => {
        state.adviceStatus = 'success';
        toastSuccessMessage('La solicitud del servicio se ha realizado con éxito');
      })
      .addCase(createAdviceAsync.rejected, (state) => {
        state.adviceStatus = 'failed';
      });
  },
});

export const getCompaniesList = (state: RootState) => state.company.companies;
export const getStatusFetchCompany = (state: RootState) => state.company.status;
export const getCompanyState = (state: RootState) => state.company;

export default companySlice.reducer;
