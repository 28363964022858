import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createAdvice,
  createCompany,
  editCompany,
  getAllCompanies,
  getAssignedCompanies,
  getCompanies,
  getCompany,
  getCompanyPlan,
  getKAMByCompany,
  updateCompanyProfile,
} from '../../../api/company';
import {
  ICreateAdviceProps,
  IGetCompaniesProps,
  IGetKAMByCompanyProps,
  IUpdateCompanyProfileProps,
} from '../../../api/company/models';
import { errorProcesor } from '../../../utils/errorProcesor';
import { ICreateCompanyAsyncProps, IEditCompanyAsyncProps, IGetCompanyParams } from '../models';

// Acción asíncrona para crear
export const createCompanyAsync = createAsyncThunk(
  'company/createCompany',
  async ({ token, data }: ICreateCompanyAsyncProps, { rejectWithValue, dispatch }) => {
    try {
      const response = await createCompany(token, data);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
// Acción asíncrona para obtener
export const getCompanyAsync = createAsyncThunk(
  'company/getCompany',
  async ({ token, companyId }: IGetCompanyParams, { rejectWithValue, dispatch }) => {
    try {
      const response = await getCompany(companyId, token);
      return errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
// Acción asíncrona para editar
export const editCompanyAsync = createAsyncThunk(
  'company/editCompany',
  async ({ token, id, data }: IEditCompanyAsyncProps, { rejectWithValue, dispatch }) => {
    try {
      const response = await editCompany(token, id, data);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const getCompaniesAsync = createAsyncThunk(
  'company/getCompanies',
  async (args: IGetCompaniesProps, { rejectWithValue, dispatch }) => {
    try {
      const response = await getCompanies(args);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const getAllCompaniesAsync = createAsyncThunk(
  'company/getAllCompanies',
  async ({ token }: { token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await getAllCompanies(token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const getCompanyPlanAsync = createAsyncThunk(
  'company/getCompanyPlan',
  async ({ token, companyId }: IGetCompanyParams, { rejectWithValue, dispatch }) => {
    try {
      const response = await getCompanyPlan(token, companyId);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const getAssignedCompaniesKamAsync = createAsyncThunk(
  'company/getAssignedCompaniesKam',
  async ({ token }: { token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await getAssignedCompanies(token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const getKAMByCompanyAsync = createAsyncThunk(
  'company/getKAM',
  async (args: IGetKAMByCompanyProps, { rejectWithValue, dispatch }) => {
    try {
      const response = await getKAMByCompany(args);
      return errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateCompanyProfileAsync = createAsyncThunk(
  'company/updateProfile',
  async (args: IUpdateCompanyProfileProps, { rejectWithValue, dispatch }) => {
    try {
      const response = await updateCompanyProfile(args);
      return errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const createAdviceAsync = createAsyncThunk(
  'company/create/advice',
  async (args: ICreateAdviceProps, { rejectWithValue, dispatch }) => {
    try {
      const response = await createAdvice(args);
      return errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
