import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ICompanyDetail } from '../../api/company/models';
import { RootState } from '../../store';
import { getCompanyAsync } from '../companySlice/thunks';
import { ICompanyAplicationState } from './models';
import { getAllCompanyApplicationsAsync } from './thunks';

export const initialState: ICompanyAplicationState = {
  list: [],
  selectedCompanyApplication: null,
  status: 'idle',
};

const companyApplicationSlice = createSlice({
  name: 'allCompany',
  initialState,
  reducers: {
    setSelectedCompanyApplication: (state, action: PayloadAction<ICompanyDetail>) => {
      state.selectedCompanyApplication = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // getAllCompanyApplicationsAsync
      .addCase(getAllCompanyApplicationsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllCompanyApplicationsAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.list = action.payload.data.list;
      })
      .addCase(getAllCompanyApplicationsAsync.rejected, (state) => {
        state.status = 'failed';
      })

      // getCompanyAsync
      .addCase(getCompanyAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCompanyAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.selectedCompanyApplication = action.payload.data;
      })
      .addCase(getCompanyAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { setSelectedCompanyApplication } = companyApplicationSlice.actions;

export const getCompanyApplicationsState = (state: RootState) => state.companyApplications;
export const getCompanyApplicationsList = (state: RootState) => state.companyApplications.list;
export const getCompanyApplicationStatus = (state: RootState) => state.companyApplications.status;
export const getSelectedCompanyApplication = (state: RootState) => state.companyApplications.selectedCompanyApplication;

export default companyApplicationSlice.reducer;
