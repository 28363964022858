import URL from './url';
import { ICandidateOffersResponse } from './models';
import { ICreateOffer, IEditOffer } from '../../models/ICandidateOffer';

export const getOffersByCandidate = async (token: string, candidateId: string): Promise<ICandidateOffersResponse> => {
  const { url, params } = URL.getOffersByCandidate(token, candidateId);
  const response = await fetch(url, params);
  return response.json();
};

export const createOffer = async (
  data: ICreateOffer,
  candidateId: string,
  token: string
): Promise<ICandidateOffersResponse> => {
  const { url, params } = URL.createOffer(data, candidateId, token);
  const response = await fetch(url, params);
  return await response.json();
};

export const editOffer = async (
  data: IEditOffer,
  offerId: string,
  candidateId: string,
  token: string
): Promise<ICandidateOffersResponse> => {
  const { url, params } = URL.editOffer(data, offerId, candidateId, token);
  const response = await fetch(url, params);
  return await response.json();
};
