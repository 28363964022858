import { useEffect } from 'react';
import { getAuthenticationStatus } from '../../redux/authSlice';
import { getSessionAsync } from '../../redux/authSlice/thunks';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

export const useSession = () => {
  const dispatch = useAppDispatch();
  const status = useAppSelector(getAuthenticationStatus);
  const isLoadingSession = status === 'loadingSession';

  useEffect(() => {
    dispatch(getSessionAsync());
  }, []);

  return { isLoadingSession };
};
