import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

type TThunksMetadata = {
  meta: {
    requestStatus: 'fulfilled' | 'rejected';
  };
};

export const useThunkActions = () => {
  const navigate = useNavigate();

  const handleGoBack = useCallback(<T extends TThunksMetadata>(action: Promise<T>) => {
    action.then((res) => {
      if (res.meta.requestStatus === 'fulfilled') navigate(-1);
    });
  }, []);

  return { handleGoBack };
};
