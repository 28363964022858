import { IFetchOptions } from '../../../models/IFetch';
import { API_URL } from '../../../utils/constants/api';
import { getHeaders } from '../../utils';
import { ICreateCustomerPlanProps, IFetchPendingCustomerPlanProps, IUpdateCustomerPlanProps } from '../models';

export default {
  fetchCustomerPlan: (token: string): IFetchOptions => ({
    url: `${API_URL}/company/my-plan`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),

  createCustomerPlan: ({ token, plan }: ICreateCustomerPlanProps): IFetchOptions => ({
    url: `${API_URL}/company/my-plan`,
    params: {
      method: 'POST',
      headers: getHeaders(token),
      body: JSON.stringify({ plan }),
    },
  }),

  updatePlan: ({ token, id, data }: IUpdateCustomerPlanProps): IFetchOptions => ({
    url: `${API_URL}/company/plan/${id}`,
    params: {
      method: 'PUT',
      headers: getHeaders(token),
      body: JSON.stringify(data),
    },
  }),

  fetchPendingCustomerPlan: ({ token, id }: IFetchPendingCustomerPlanProps): IFetchOptions => ({
    url: `${API_URL}/company/${id}/plan/pending`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),
};
