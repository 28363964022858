import { createSlice } from '@reduxjs/toolkit';
import { toastSuccessMessage } from '../../utils/toast';
import { createServiceAsync, editServiceAsync, getServicesAsync, getServiceAsync, deleteServiceAsync } from './thunks';
import { RootState } from '../../store';
import { IServiceState } from './models';

export const initialState: IServiceState = {
  services: [],
  selectedService: null,
  status: 'idle',
  error: null,
};

const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setSelectedService: (state, action) => {
      state.selectedService = action.payload;
    },
  },
  extraReducers: (builder) => {
    //getServices async
    builder
      .addCase(getServicesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getServicesAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.services = action.payload.data;
      })
      .addCase(getServicesAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      //get Service async
      .addCase(getServiceAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getServiceAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.selectedService = action.payload.data;
      })
      .addCase(getServiceAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      //createServiceAsync
      .addCase(createServiceAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createServiceAsync.fulfilled, (state, action) => {
        state.services = action.payload.data.list;
        state.status = 'success';
        toastSuccessMessage('Servicio creado con éxito');
      })
      .addCase(createServiceAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      //editServiceAsync
      .addCase(editServiceAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editServiceAsync.fulfilled, (state, action) => {
        state.services = action.payload.data.list;
        state.status = 'success';
        toastSuccessMessage('Servicio editado con éxito');
      })
      .addCase(editServiceAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      //deleteServiceAsync
      .addCase(deleteServiceAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteServiceAsync.fulfilled, (state, action) => {
        state.services = action.payload.data;
        state.status = 'success';
        toastSuccessMessage('Servicio eliminado con éxito');
      })
      .addCase(deleteServiceAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const { setSelectedService } = serviceSlice.actions;
export const getServicesState = (state: RootState) => state.service;
export const getServiceStatus = (state: RootState) => state.service.status;
export const getSelectedService = (state: RootState) => state.service.selectedService;
export const getServicesList = (state: RootState) => state.service.services;

export default serviceSlice.reducer;
