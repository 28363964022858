import { IFetchRemmunerationsByRecruiterProps, IRemunerationResponse } from './models';
import URL from './url';

export const getAllRemunerations = async (token: string): Promise<IRemunerationResponse> => {
  const { url, params } = URL.getAllRemunerations(token);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};

export const getRemunerationsByRecruiter = async (
  args: IFetchRemmunerationsByRecruiterProps
): Promise<IRemunerationResponse> => {
  const { url, params } = URL.getRemunerationsByRecruiter(args);
  const response = await fetch(url, params);
  const data = await response.json();
  return data;
};
