import { API_URL } from '../../../utils/constants/api';
import { IFetchOptions } from '../../../models/IFetch';
import { getHeaders } from '../../utils';

export default {
  getJobOffersByRecruiter: (token: string): IFetchOptions => ({
    url: `${API_URL}/job-offers/open`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),
};
