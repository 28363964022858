/* istanbul ignore file */
import { IActivateAndResetProps, ISignInProps } from '../../../models/IAuth';
import { IFetchOptions } from '../../../models/IFetch';
import { API_URL } from '../../../utils/constants/api';
import { getHeaders } from '../../utils';
import { ActivateOrReset } from '../models';

export default {
  postLogin: (data: ISignInProps): IFetchOptions => ({
    url: `${API_URL}/auth/login`,
    params: {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      mode: 'cors',
      body: JSON.stringify(data),
    },
  }),
  forgotPassword: (email: string): IFetchOptions => ({
    url: `${API_URL}/user/account/forgot-password`,
    params: {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    },
  }),
  activateAndResetPassword: (path: ActivateOrReset, data: IActivateAndResetProps): IFetchOptions => ({
    url: `${API_URL}/user/account/${path}`,
    params: {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    },
  }),
  getSession: (): IFetchOptions => ({
    url: `${API_URL}/auth/session`,
    params: {
      method: 'GET',
      headers: getHeaders(null),
      credentials: 'include',
      mode: 'cors',
    },
  }),

  logout: (): IFetchOptions => ({
    url: `${API_URL}/auth/logout`,
    params: {
      method: 'GET',
      credentials: 'include',
    },
  }),
};
