import { IFetchOptions } from '../../../models/IFetch';
import { API_URL } from '../../../utils/constants/api';
import { getHeaders, setParamsToURL } from '../../utils';
import { IFetchJobOffersWithCandidadatesAssignedProps, IFetchWithCandidatesByCompanyProps } from '../models';

export default {
  fetchWithCandidatesByCompany: ({ token, id, createdAt }: IFetchWithCandidatesByCompanyProps): IFetchOptions => ({
    url: setParamsToURL(`${API_URL}/job-offers/candidates/company/${id}`, { createdAt }),
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),

  fetchJobOffersWithCandidatesAssigned: ({
    token,
    ...params
  }: IFetchJobOffersWithCandidadatesAssignedProps): IFetchOptions => ({
    url: setParamsToURL(`${API_URL}/job-offers/candidates/company/assigned`, params),
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),
};
