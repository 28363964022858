import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchRecruiterSummary } from '../../../api/homeSummary';
import { IToken } from '../../../models/IToken';
import { errorProcesor } from '../../../utils/errorProcesor';

export const fetchHomeSummaryRecruiterAsync = createAsyncThunk(
  'homeRecruiter/fetch',
  async ({ token }: IToken, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchRecruiterSummary(token);
      return errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
