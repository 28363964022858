import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  fetchJobOffersWithCandidatesAssigned,
  fetchWithCandidatesByCompany,
} from '../../../api/jobOfferWithCandidates';
import {
  IFetchJobOffersWithCandidadatesAssignedProps,
  IFetchWithCandidatesByCompanyProps,
} from '../../../api/jobOfferWithCandidates/models';
import { errorProcesor } from '../../../utils/errorProcesor';

export const fetchWithCandidatesByCompanyAsync = createAsyncThunk(
  'jobOfferWithCandidates/fetchByCompany',
  async (args: IFetchWithCandidatesByCompanyProps, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchWithCandidatesByCompany(args);
      return errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchJobOffersWithCandidatesAssignedAsync = createAsyncThunk(
  'jobOfferWithCandidates/fetchAssigned',
  async (args: IFetchJobOffersWithCandidadatesAssignedProps, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchJobOffersWithCandidatesAssigned(args);
      return errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
