import { IFetchOptions } from '../../../models/IFetch';
import { IUpdateWorkOrder } from '../../../models/IWorkOrder';
import { API_URL } from '../../../utils/constants/api';
import { getHeaders, setParamsToURL } from '../../utils';
import { IUpdateAdviceProps } from '../models';

export default {
  getWorkOrders: (token: string, limit?: number, offset?: number, status?: string): IFetchOptions => ({
    url: setParamsToURL(`${API_URL}/orders`, { limit, offset, status }),
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),
  getWorkOrderById: (workdId: string, token: string): IFetchOptions => ({
    url: `${API_URL}/orders/${workdId}`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),
  updateWorkOrders: (id: string, token: string, order: IUpdateWorkOrder): IFetchOptions => ({
    url: `${API_URL}/orders/${id}`,
    params: {
      method: 'PUT',
      headers: getHeaders(token),
      body: JSON.stringify(order),
    },
  }),

  updateAdvice: ({ token, id, order }: IUpdateAdviceProps): IFetchOptions => ({
    url: `${API_URL}/company/advice/${id}`,
    params: {
      method: 'PUT',
      headers: getHeaders(token),
      body: JSON.stringify(order),
    },
  }),
};
