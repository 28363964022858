import { createSlice } from '@reduxjs/toolkit';
import { IUserActivationState } from './models';
import { activateUserAsync, deactivateUserAsync } from './thunks';
import { RootState } from '../../store';
import { toastSuccessMessage } from '../../utils/toast';

export const initialState: IUserActivationState = {
  status: 'idle',
};

const userActivationSlice = createSlice({
  name: 'userActivation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //deactivateUserAsync
      .addCase(deactivateUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deactivateUserAsync.fulfilled, (state) => {
        state.status = 'success';
        toastSuccessMessage('Usuario suspendido correctamente');
      })
      .addCase(deactivateUserAsync.rejected, (state) => {
        state.status = 'failed';
      })

      //activateUserAsync
      .addCase(activateUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(activateUserAsync.fulfilled, (state) => {
        state.status = 'success';
        toastSuccessMessage('Usuario activado correctamente');
      })
      .addCase(activateUserAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const getUserActivationState = (state: RootState) => state;

export default userActivationSlice.reducer;
