import { getPopUpState, onClose, openWithContent } from '../redux/popUpSlice';
import type { IPopUpContent } from '../redux/popUpSlice/models';
import { useAppDispatch, useAppSelector } from '../store/hooks';

export const usePopUp = () => {
  const dispatch = useAppDispatch();
  const { isOpen, content, popUpContext } = useAppSelector(getPopUpState);

  const openPopUp = (content: IPopUpContent) => dispatch(openWithContent(content));

  const closePopUp = () => dispatch(onClose());

  return { isOpen, content, popUpContext, openPopUp, closePopUp };
};
