/* istanbul ignore file */
interface IError {
  name: string;
  message: string;
}

export interface IBaseFetch {
  statusCode: number;
  message?: string;
  error?: IError;
}

export interface IFetchOptions {
  url: string;
  params: RequestInit;
}

export interface IFetchResponse<T> extends IBaseFetch {
  data: T;
}

interface ICreatedResponse<T> {
  created: T;
  list: T[];
}

export interface IFetchUpdatedResponse<T> extends IBaseFetch {
  data: {
    list: T[];
  };
}

export type IFetchCreatedResponse<T> = IFetchResponse<ICreatedResponse<T>>;

export interface IFetchReject extends IBaseFetch {
  error: IError;
}

export type TFetchStatus = 'idle' | 'loading' | 'failed' | 'success';

export enum HttpStatus {
  OK = 200,
  CREATED = 201,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  NOT_FOUND = 404,
  INTERNAL_ERROR = 500,
  CONFLICT_ERROR = 409,
}
