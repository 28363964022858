import { ICompanyDetail } from '../api/company/models';
import { IBaseDB } from './General';
import { ICandidateJobOffer } from './ICandidateJobOffer';
import { ISkillGroup } from './ISkill';

export enum JOB_OFFER_STATUS {
  OPEN = 'Abierto',
  COMPLETED = 'Completado',
  ON_PAUSE = 'En pausa',
  CANCELLED = 'Cancelado',
}

export interface ICreateJobOffer<T = string> {
  name: string;
  salary: number;
  estimatedDate: string;
  workingDay: string;
  level: string;
  modality: string;
  requiredVacancies: number;
  hasIncentive: boolean;
  responsibilities: string[];
  requirements: string[];
  benefits: string[];
  about?: string;
  isStoringBenefits: boolean;
  isStoringAbout: boolean;
  skills: T[];
}

export interface ICreateJobOfferApiData extends ICreateJobOffer {
  company: string;
}

export interface IUpdateJobOffer extends Partial<ICreateJobOfferApiData> {
  status?: string;
}

export interface IJobOffer<T = ISkillGroup> extends IBaseDB, ICreateJobOffer<T> {
  status: string;
  isUrgent: boolean;
  company: ICompanyDetail;
  isSuspended?: boolean;
}

export interface IJobOfferWithCandidates extends IJobOffer {
  candidates: ICandidateJobOffer[];
}
