import { IFetchOptions } from '../../../models/IFetch';
import { IFetchSummaryParams } from '../../../models/IHomeSummary';
import { API_URL } from '../../../utils/constants/api';
import { getHeaders } from '../../utils';

export default {
  fetchSummary: ({ token, user }: IFetchSummaryParams): IFetchOptions => ({
    url: `${API_URL}/summary/home/${user}`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),
};
