import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAdminSummary } from '../../../api/homeSummary';
import { errorProcesor } from '../../../utils/errorProcesor';

export const getSummaryAsync = createAsyncThunk(
  'homeAdmin/getSummary',
  async ({ token }: { token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchAdminSummary(token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
