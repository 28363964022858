export const getHeaders = (token: string | null): HeadersInit => {
  if (token) {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
  } else {
    return {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
  }
};

export const setParamsToURL = (url: string, params: Record<string, any>) => {
  const baseUrl = new URL(url);
  const PARAMS = new URLSearchParams(baseUrl.search);
  Object.entries(params).map(([key, value]) => {
    if (value) PARAMS.set(key, value);
  });
  baseUrl.search = PARAMS.toString();
  return baseUrl.toString();
};

export const fakePromise = <T>(mock: T) =>
  new Promise<T>((resolve) => {
    setTimeout(() => resolve(mock), 2000);
  });
