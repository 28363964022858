import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { ICandidatesOffersState } from './models';
import { toastSuccessMessage } from '../../utils/toast';
import { createCandidateOfferAsync, editCandidateOfferAsync, fetchOffersByCandidateAsync } from './thunks';

export const initialState: ICandidatesOffersState = {
  offersListByCandidate: [],
  status: 'idle',
  error: '',
};

const candidatesOffersSlice = createSlice({
  name: 'candidatesOffers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //fetchCandidateOffers
      .addCase(fetchOffersByCandidateAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchOffersByCandidateAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.offersListByCandidate = action.payload.data;
      })
      .addCase(fetchOffersByCandidateAsync.rejected, (state) => {
        state.status = 'failed';
      })

      //createOfferAsync
      .addCase(createCandidateOfferAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createCandidateOfferAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.offersListByCandidate = action.payload.data;
        toastSuccessMessage('Oferta creada con éxito');
      })
      .addCase(createCandidateOfferAsync.rejected, (state) => {
        state.status = 'failed';
      })

      //editOfferAsync
      .addCase(editCandidateOfferAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editCandidateOfferAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.offersListByCandidate = action.payload.data;
        toastSuccessMessage('Oferta editada con éxito');
      })
      .addCase(editCandidateOfferAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const getCandidatesOffersState = (state: RootState) => state.candidatesOffers;

export default candidatesOffersSlice.reducer;
