import { useState } from 'react';

export const useCreatedAtFilter = () => {
  const [createdAt, setCreatedAt] = useState('-1');

  const updateCreatedAtFilter = (val?: string) => {
    if (!val || val === createdAt) return;
    setCreatedAt(val);
  };

  return { createdAt, updateCreatedAtFilter };
};
