import { API_URL } from '../../../utils/constants/api';
import { getHeaders } from '../../utils';

export default {
  getTransferData: (token: string) => ({
    url: `${API_URL}/config/bank`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),
};
