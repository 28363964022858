import { IGetCandidateByIdResponse, IGetCandidatesResponse, IUpdateCandidateStatusProps } from './models';
import URL from './url';

export const getCandidates = async (token: string, jobOffer: string): Promise<IGetCandidatesResponse> => {
  const { url, params } = URL.getCandidates(token, jobOffer);
  const response = await fetch(url, params);
  return response.json();
};

export const getCandidateById = async (token: string, id: string): Promise<IGetCandidateByIdResponse> => {
  const { url, params } = URL.getCandidateById(token, id);
  const response = await fetch(url, params);
  return response.json();
};

export const updateCandidateStatus = async (args: IUpdateCandidateStatusProps): Promise<IGetCandidatesResponse> => {
  const { url, params } = URL.updateCandidateStatus(args);
  const response = await fetch(url, params);
  return response.json();
};
