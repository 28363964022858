import { ICreatePlan, IUpdatePlan } from '../../models/IPlan';
import { IPlanListResponse, IPlanResponse, IPlanUpdatedResponse, IUpdateOrderPreferenceProps } from './models';
import URL from './url';

export const createPlan = async (planData: ICreatePlan, token: string): Promise<IPlanUpdatedResponse> => {
  const { url, params } = URL.createPlan(planData, token);
  const response = await fetch(url, params);
  return await response.json();
};

export const getOnePlan = async (planId: string, token: string): Promise<IPlanResponse> => {
  const { url, params } = URL.getOnePlan(planId, token);
  const response = await fetch(url, params);
  return await response.json();
};

export const getPlans = async (token: string): Promise<IPlanListResponse> => {
  const { url, params } = URL.getPlans(token);
  const response = await fetch(url, params);
  return await response.json();
};

export const updatePlan = async (token: string, id: string, data: IUpdatePlan): Promise<IPlanUpdatedResponse> => {
  const { url, params } = URL.updatePlan(token, id, data);
  const response = await fetch(url, params);
  return await response.json();
};

export const deletePlan = async (planId: string, token: string): Promise<IPlanListResponse> => {
  const { url, params } = URL.deletePlan(planId, token);
  const response = await fetch(url, params);
  return await response.json();
};

export const getPlansActive = async (token: string): Promise<IPlanListResponse> => {
  const { url, params } = URL.getPlansActive(token);
  const response = await fetch(url, params);
  return await response.json();
};

export const updateOrderPreference = async (args: IUpdateOrderPreferenceProps): Promise<IPlanListResponse> => {
  const { url, params } = URL.updateOrderPreference(args);
  const response = await fetch(url, params);
  return response.json();
};
