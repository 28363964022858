import { IBill, IUpdateBill } from '../../models/IBill';
import { IPaginationResponse } from '../../models/IPagination';
import { IBillUpdatedResponse, IFetchBillAdminResponse, IFetchBillParams } from './models';
import URL from './url';

export const fetchBillsByCompany = async (args: IFetchBillParams): Promise<IPaginationResponse<IBill>> => {
  const { url, params } = URL.fetchBillsByCompany(args);
  const response = await fetch(url, params);
  return await response.json();
};

export const editBill = async (token: string, id: string, data: IUpdateBill): Promise<IBillUpdatedResponse> => {
  const { url, params } = URL.editBill(token, id, data);
  const response = await fetch(url, params);
  return await response.json();
};

export const fetchAllBills = async (token: string): Promise<IFetchBillAdminResponse> => {
  const { url, params } = URL.fetchAllBills(token);
  const response = await fetch(url, params);
  return await response.json();
};
