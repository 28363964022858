import { IFetchOptions } from '../../../models/IFetch';
import { ICreateSkillGroup, IEditSkillGroup } from '../../../models/ISkill';
import { API_URL } from '../../../utils/constants/api';
import { getHeaders } from '../../utils';

export default {
  createCustomerSkillsGroup: (SkillsGroupData: ICreateSkillGroup, token: string): IFetchOptions => ({
    url: `${API_URL}/skills`,
    params: {
      method: 'POST',
      headers: getHeaders(token),
      body: JSON.stringify(SkillsGroupData),
    },
  }),
  getCustomerSkillGroups: (companyId: string, token: string): IFetchOptions => ({
    url: `${API_URL}/skills/${companyId}`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),
  getOneCustomerSkillGroup: (Id: string, token: string): IFetchOptions => ({
    url: `${API_URL}/skills/one/${Id}`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),
  editCustomerSkillGroup: (Id: string, updatedData: IEditSkillGroup, token: string): IFetchOptions => ({
    url: `${API_URL}/skills/${Id}`,
    params: {
      method: 'PUT',
      headers: getHeaders(token),
      body: JSON.stringify(updatedData),
    },
  }),
  deleteCustomerSkillGroup: (Id: string, token: string): IFetchOptions => ({
    url: `${API_URL}/skills/${Id}`,
    params: {
      method: 'DELETE',
      headers: getHeaders(token),
    },
  }),
  getCustomerTemplateSkillGroups: (companyId: string, token: string): IFetchOptions => ({
    url: `${API_URL}/skills/templates/${companyId}`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),
  deleteCustomerTemplateSkillGroup: (Id: string, token: string): IFetchOptions => ({
    url: `${API_URL}/skills/template/${Id}`,
    params: {
      method: 'DELETE',
      headers: getHeaders(token),
    },
  }),
};
