import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { TabBarProps } from '../components/TabBar';

type IUseTabBar = Pick<TabBarProps, 'tabs'>;

export const useTabBar = ({ tabs }: IUseTabBar) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const tab = tabs.find((t) => t.path === pathname);
    if (tab) setTabIndex(tab.id);
  }, []);

  const onTabClick = (index: number) => {
    navigate(tabs[index]?.path ?? '');
  };

  return { tabIndex, onTabClick };
};
