import { IFetchOptions } from '../../../models/IFetch';
import { ICreatePlan, IUpdatePlan } from '../../../models/IPlan';
import { API_URL } from '../../../utils/constants/api';
import { getHeaders } from '../../utils';
import { IUpdateOrderPreferenceProps } from '../models';

export default {
  createPlan: (planData: ICreatePlan, token: string): IFetchOptions => ({
    url: `${API_URL}/plans`,
    params: {
      method: 'POST',
      headers: getHeaders(token),
      body: JSON.stringify(planData),
    },
  }),

  getOnePlan: (planId: string, token: string): IFetchOptions => ({
    url: `${API_URL}/plans/${planId}`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),

  getPlans: (token: string): IFetchOptions => ({
    url: `${API_URL}/plans`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),

  getPlansActive: (token: string): IFetchOptions => ({
    url: `${API_URL}/plans/active`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),

  updatePlan: (token: string, id: string, updatedData: IUpdatePlan): IFetchOptions => ({
    url: `${API_URL}/plans/${id}`,
    params: {
      method: 'PUT',
      headers: getHeaders(token),
      body: JSON.stringify(updatedData),
    },
  }),

  deletePlan: (planId: string, token: string): IFetchOptions => ({
    url: `${API_URL}/plans/${planId}`,
    params: {
      method: 'DELETE',
      headers: getHeaders(token),
    },
  }),

  updateOrderPreference: ({ token, id, position }: IUpdateOrderPreferenceProps): IFetchOptions => ({
    url: `${API_URL}/plans/${id}/order/${position}`,
    params: {
      headers: getHeaders(token),
    },
  }),
};
