export type TColors = keyof typeof color;

export const color = {
  orangeOne: '#ED623F', //Puesta de sol
  orangeTwo: '#EF7253', //Puesta de sol 2
  orangeThree: '#F39179', //Puesta de sol 3
  orangeFour: '#FAD0C6', //Puesta de sol 4
  orangeFive: '#FAD0C6BD', //Puesta de sol 5
  turquoiseOne: '#107380', //Mar
  turquoiseTwo: '#4C96A0', //Mar 2
  turquoiseThree: '#9FC7CC', //Mar 3
  turquoiseFour: '##CFE3E6', //Mar 4
  lightblue: '#24B9CA', //Olas de Mar
  grayOne: '#384649', //Gris Marino
  grayTwo: '#5A5E62', //Gris Piedra
  grayTwoLight: '#5A5E62CC', //Gris Piedra Liviano
  grayThree: '#C4C8C8', //Gris Arena
  grayFour: '#ADAFB1',
  grayFive: '#8c8f92',
  grayFiveLight: '#E0E3E3',
  graySix: '#747E80',
  brown: '#C45133',
  red: '#C00700',
  yellow: '#FFC647',
  green: '#0BAD2F',
  lightGreen: '#00C853',
  dark: '#241F2E', //Horizonte
  neutral: '#F6F7F7', //Gris Perla
  white: '#FFFFFF', //Playa Blanca
};
