import { createAsyncThunk } from '@reduxjs/toolkit';
import { getJobOffersByRecruiter } from '../../../api/jobOffersOpen';
import { errorProcesor } from '../../../utils/errorProcesor';

export const fetchJobOfferByRecruiterAsync = createAsyncThunk(
  'jobOffer/fetchByRecruiter',
  async ({ token }: { token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await getJobOffersByRecruiter(token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
