import { IFetchResponse } from '../../../src/models/IFetch';
import { IAdmin, ICreateAdmin, IGeneralUsers, IUpdateUser } from '../../../src/models/IUser';
import {
  IAdminUpdateResponse,
  IAdminUserResponse,
  IFetchUsersAllProps,
  IFetchUsersResponse,
  IGetUsersAdminResponse,
} from './models';
import URL from './url';

export const getUsersAdmin = async (token: string): Promise<IGetUsersAdminResponse> => {
  const { url, params } = URL.getUsersAdmin(token);
  const response = await fetch(url, params);
  return await response.json();
};

export const createUser = async (userData: ICreateAdmin, token: string, role: string): Promise<IAdminUserResponse> => {
  const { url, params } = URL.createUser(userData, token, role);
  const response = await fetch(url, params);
  return await response.json();
};

export const getUser = async (userId: string, token: string): Promise<IAdminUserResponse> => {
  const { url, params } = URL.getUser(userId, token);
  const response = await fetch(url, params);
  return await response.json();
};

export const editUser = async (
  userId: string,
  updatedData: Partial<IAdmin>,
  token: string
): Promise<IAdminUpdateResponse> => {
  const { url, params } = URL.editUser(userId, updatedData, token);
  const response = await fetch(url, params);
  return await response.json();
};

export const updateUserProfile = async (
  token: string,
  userId: string,
  updateData: IUpdateUser
): Promise<IFetchResponse<IGeneralUsers>> => {
  const { url, params } = URL.editUserProfile(token, userId, updateData);
  const response = await fetch(url, params);
  return await response.json();
};

export const getProfile = async (token: string): Promise<IFetchResponse<IGeneralUsers>> => {
  const { url, params } = URL.getProfile(token);
  const response = await fetch(url, params);
  return await response.json();
};

export const updateCustomerAvatar = async (
  token: string,
  userId: string,
  updatedData: FormData
): Promise<IFetchResponse<string>> => {
  const { url, params } = URL.editAvatar(token, userId, updatedData);
  const response = await fetch(url, params);
  return await response.json();
};

export const fetchUsersAll = async (args: IFetchUsersAllProps): Promise<IFetchUsersResponse> => {
  const { url, params } = URL.fetchUsersAll(args);
  const response = await fetch(url, params);
  return response.json();
};
