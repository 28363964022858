import { IFetchHomeSummary } from '../../models/IHomeSummary';
import { IAdminHome } from '../../redux/homeAdminSlice/models';
import { IKamHome } from '../../redux/homeKamSlice/models';
import { IRecruiterHome } from '../../redux/homeRecruiterSlice/models';
import URL from './url';

export const fetchAdminSummary: IFetchHomeSummary<IAdminHome> = async (token) => {
  const { url, params } = URL.fetchSummary({ token, user: 'admin' });
  const response = await fetch(url, params);
  return response.json();
};

export const fetchKamSummary: IFetchHomeSummary<IKamHome> = async (token) => {
  const { url, params } = URL.fetchSummary({ token, user: 'kam' });
  const response = await fetch(url, params);
  return response.json();
};

export const fetchRecruiterSummary: IFetchHomeSummary<IRecruiterHome> = async (token) => {
  const { url, params } = URL.fetchSummary({ token, user: 'recruiter' });
  const response = await fetch(url, params);
  return response.json();
};
