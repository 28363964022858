import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchTransferData } from '../../../api/config';
import { errorProcesor } from '../../../utils/errorProcesor';

export const fetchTransferDataAsync = createAsyncThunk(
  'config/fetchTransferDataAsync',
  async ({ token }: { token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchTransferData(token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
