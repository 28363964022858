import {
  ICreateCustomerPlanProps,
  ICustomerPlanResponse,
  IFetchPendingCustomerPlanProps,
  IUpdateCustomerPlanProps,
} from './models';
import URL from './url';

export const fetchCustomerPlan = async (token: string): Promise<ICustomerPlanResponse> => {
  const { url, params } = URL.fetchCustomerPlan(token);
  const response = await fetch(url, params);
  return response.json();
};

export const createCustomerPlan = async (args: ICreateCustomerPlanProps): Promise<ICustomerPlanResponse> => {
  const { url, params } = URL.createCustomerPlan(args);
  const response = await fetch(url, params);
  return response.json();
};

export const updateCustomerPlan = async (args: IUpdateCustomerPlanProps): Promise<ICustomerPlanResponse> => {
  const { url, params } = URL.updatePlan(args);
  const response = await fetch(url, params);
  return response.json();
};

export const fetchPendingCustomerPlan = async (
  args: IFetchPendingCustomerPlanProps
): Promise<ICustomerPlanResponse> => {
  const { url, params } = URL.fetchPendingCustomerPlan(args);
  const response = await fetch(url, params);
  return response.json();
};
