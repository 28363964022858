import { useMemo, useState } from 'react';
import type { IField, IRules } from '../models/ISchema';

interface IFormProps<T> {
  fieldSchema: T;
  validateOnInit?: boolean;
}

export const useForm = <T extends object>({ fieldSchema, validateOnInit = false }: IFormProps<T>) => {
  const schema = useMemo(() => schemaProcessor(fieldSchema), [fieldSchema]);
  const { initialValues, validationSchema, requiredFields } = schema;
  const initialErr = validateOnInit ? ({ [Object.keys(initialValues)[0]]: { error: false, message: '' } } as any) : {};
  const [fields, setFields] = useState<TFields<T>>(initialValues);
  const [errors, setErrors] = useState<TFormErrors<T>>(initialErr);
  const hasRequiredValues = requiredFields.every((name) => fields[name]);
  const isValidForm = hasRequiredValues && Object.keys(errors).length === 0;

  const onChangeField: OnChangeField<T> = ({ name, value }) => {
    setFields({ ...fields, [name]: value });
    if (!validationSchema[name]) return;

    const temp = validateField(name, value);
    const errs = { ...errors };

    if (temp) {
      setErrors({ ...errs, [name]: temp });
    } else {
      delete errs[name];
      setErrors(errs);
    }
  };

  const updateValues = (values: Partial<TFields<T>>) => setFields({ ...fields, ...values });
  const resetForm = () => setFields(initialValues);

  const onSubmit = () => {
    const flds = Object.entries(fields);
    const errs: any = {};

    flds.forEach(([key, value]) => {
      if (!validationSchema[key]) return;
      const temp = validateField(key, value);
      if (temp) errs[key] = temp;
    });

    setErrors(errs);

    return Object.keys(errs || {}).length === 0;
  };

  const validateField = (fieldName: any, fieldValue: any) => {
    const validations = Object.entries(validationSchema[fieldName]) as [keyof IRules, any][];
    const requiredByFieldValue = fields[validationSchema[fieldName]['requiredBy']];

    if (requiredByFieldValue) {
      if (fieldValue) return;

      return {
        error: validationRules.requiredBy(fieldValue),
        message: errorMessages.requiredBy(),
      };
    }

    const all = validations.map(([keyValidation, val]) => ({
      error:
        keyValidation === 'conditions'
          ? validationRules[keyValidation](fieldValue, val.regex)
          : validationRules[keyValidation].length > 1
          ? validationRules[keyValidation](fieldValue, val)
          : validationRules[keyValidation](val),
      message:
        keyValidation === 'conditions'
          ? errorMessages[keyValidation](val.errorMessage)
          : errorMessages[keyValidation].length > 0
          ? errorMessages[keyValidation](val)
          : errorMessages[keyValidation](),
    }));

    const required = all.find((el) => el?.message === 'Campo requerido');
    if (!fieldValue) return required;

    const [temp] = all.filter((validation) => validation?.error && validation?.message !== required?.message);
    return temp;
  };

  return { fields, errors, onChangeField, onSubmit, isValidForm, resetForm, updateValues };
};

const schemaProcessor = <T extends object>(fieldSchema: T) => {
  const initialState: SchemaProcessor<T> = {
    initialValues: {} as SchemaProcessor<T>['initialValues'],
    validationSchema: {},
    requiredFields: [],
  };

  const schemaEntries = Object.entries(fieldSchema) as [keyof T, IField][];
  return schemaEntries.reduce((schema, [field, { initialValue, rules }]) => {
    schema.initialValues[field] = initialValue;
    schema.validationSchema[field] = rules;

    rules?.required && schema.requiredFields.push(field);

    return schema;
  }, initialState);
};

const validationRules: ValidationOptions<boolean> = {
  required: (value: boolean) => value,
  minLength: (value: string, min: number) => value.length < min,
  maxLength: (value: string, max: number) => value.length > max,
  max: (value: number, max: number) => value >= max,
  positive: (value: number) => value < 0,
  conditions: (value: string, regex: RegExp) => !regex.test(value),
  requiredBy: (value: unknown) => !value,
};

export const errorMessages: ValidationOptions<string> = {
  required: () => `Campo requerido`,
  minLength: (min: number) => `Campo debe tener al menos ${min} caracteres`,
  maxLength: (max: number) => `Campo debe tener máximo ${max} caracteres`,
  max: (max: number) => `Campo debe ser menor a ${max.toLocaleString('es-CL')}`,
  positive: () => `Campo debe ser número positivo`,
  conditions: (msg?: string) => msg ?? 'Campo inválido',
  requiredBy: () => 'Este campo debe contener un valor',
};

interface Error {
  error: boolean;
  message: string;
}

type ValidationOptions<T> = Record<keyof IRules, (...params: any) => T>;

type SchemaProcessor<T> = {
  initialValues: Record<keyof T, IField['initialValue']>;
  validationSchema: Partial<Record<keyof T, IField['rules']>>;
  requiredFields: (keyof T)[];
};

export type TFields<T = unknown> = Record<keyof T, any>;
export type TFormErrors<T = unknown> = Record<keyof T, Error>;
export type OnChangeField<T = unknown> = {
  ({ name, value }: { name: keyof T; value: any }): void;
};
