import { JOB_OFFER_STATUS } from '../models/IJobOffer';
import { color } from './color';

export const jobOfferStatusKAM = {
  bg: {
    [JOB_OFFER_STATUS.OPEN]: color.green,
    [JOB_OFFER_STATUS.COMPLETED]: color.lightblue,
    [JOB_OFFER_STATUS.CANCELLED]: color.red,
    [JOB_OFFER_STATUS.ON_PAUSE]: color.yellow,
  },
};
