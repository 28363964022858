import { createSlice } from '@reduxjs/toolkit';
import { IJobOfferRecruiterState } from './models';
import { fetchJobOfferByRecruiterAsync } from './thunks';
import { RootState } from '../../store/index';
import { fetchJobOfferByIdAsync } from '../jobOffersSlice/thunks';

export const initialState: IJobOfferRecruiterState = {
  jobOfferOpenList: [],
  status: 'idle',
  selectedJobOfferOpen: null,
};

export const jobOfferRecruiterSlice = createSlice({
  name: 'jobOfferRecruiter',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //fetchJobOfferByRecruiterAsync
      .addCase(fetchJobOfferByRecruiterAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchJobOfferByRecruiterAsync.rejected, (state) => {
        state.status = 'failed';
        state.jobOfferOpenList = [];
      })
      .addCase(fetchJobOfferByRecruiterAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.jobOfferOpenList = action.payload.data.list;
      })

      //fetchSingleJobOfferByCompanyAsync
      .addCase(fetchJobOfferByIdAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchJobOfferByIdAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchJobOfferByIdAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.selectedJobOfferOpen = action.payload.data;
      });
  },
});

export const getJobOffersOpenList = (state: RootState) => state.jobOffersOpen.jobOfferOpenList;
export const getJobOffersOpenListStatus = (state: RootState) => state.jobOffersOpen.status;
export const getJobOfferOpen = (state: RootState) => state.jobOffersOpen.selectedJobOfferOpen;

export default jobOfferRecruiterSlice.reducer;
