import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { toastErrorMessage } from '../../utils/toast';
import { IAuthState, TStatus } from './models';
import {
  activateAndResetPasswordAsync,
  fetchLoginAsync,
  forgotPasswordAsync,
  getSessionAsync,
  logoutAsync,
} from './thunks';

export const initialState: IAuthState = {
  accessToken: null,
  refreshToken: null,
  status: 'idle',
  error: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    onClearAuthenticationError: (state) => {
      state.error = null;
    },
    onSetAuthenticatedStatus: (state, action: PayloadAction<TStatus>) => {
      state.status = action.payload;
    },
    onBrokenAuthentication: (state) => {
      state.status = 'failed';
    },
    setEmailForRecovery: (state, action: PayloadAction<string>) => {
      state.recovery = { status: 'idle', email: action.payload };
    },
    removeRecoveryState: (state) => {
      state.recovery = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      //fetchLoginAsync
      .addCase(fetchLoginAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLoginAsync.rejected, (state, action) => {
        const message = action.payload as string;
        state.status = 'failed';
        state.accessToken = '';
        state.refreshToken = '';
        state.error = { name: 'auth', message };
        toastErrorMessage('Credenciales incorrectas. Por favor, inténtelo de nuevo');
      })

      //forgotPasswordAsync
      .addCase(forgotPasswordAsync.pending, (state) => {
        state.recovery = { ...state.recovery, status: 'loading' };
      })
      .addCase(forgotPasswordAsync.rejected, (state) => {
        state.recovery = { ...state.recovery, status: 'failed' };
        state.accessToken = '';
      })
      .addCase(forgotPasswordAsync.fulfilled, (state) => {
        state.recovery = { ...state.recovery, status: 'success' };
        state.accessToken = '';
        state.error = null;
      })
      // Activate and reset password
      .addCase(activateAndResetPasswordAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(activateAndResetPasswordAsync.fulfilled, (state) => {
        state.status = 'success';
      })
      .addCase(activateAndResetPasswordAsync.rejected, (state) => {
        state.status = 'failed';
      })

      // getSessionAsync
      .addCase(getSessionAsync.pending, (state) => {
        state.status = 'loadingSession';
      })
      .addCase(getSessionAsync.rejected, (state) => {
        state.status = 'failed';
      })

      // logoutAsync
      .addCase(logoutAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(logoutAsync.fulfilled, (state) => {
        state.status = 'close';
        state.accessToken = null;
        state.refreshToken = null;
      })
      .addCase(logoutAsync.rejected, (state) => {
        state.status = 'failed';
      })

      // Matchers
      // login or session fulfilled
      .addMatcher(isAnyOf(fetchLoginAsync.fulfilled, getSessionAsync.fulfilled), (state, action) => {
        const { accessToken, refreshToken } = action.payload.data.tokens;
        state.status = 'authenticated';
        state.accessToken = accessToken;
        state.refreshToken = refreshToken;
        state.error = null;
      });
  },
});

export const {
  onClearAuthenticationError,
  onSetAuthenticatedStatus,
  onBrokenAuthentication,
  setEmailForRecovery,
  removeRecoveryState,
} = authSlice.actions;

export const getAuthenticatedToken = (state: RootState): string => state.auth.accessToken ?? '';
export const getRefreshToken = (state: RootState): string => state.auth.refreshToken ?? '';
export const getAuthenticationStatus = (state: RootState) => state.auth.status;
export const getAuthenticationError = (state: RootState) => state.auth.error;
export const getAuthState = (state: RootState) => state.auth;

export default authSlice.reducer;
