import { ICreateCompany, IUpdateCompany } from '../../models/ICompany';
import {
  IAllCompaniesListResponse,
  IAssignedCompaniesListResponse,
  ICompaniesListResponse,
  ICompanyPlanResponse,
  ICompanyResponse,
  ICompanyUpdatedResponse,
  ICreateAdviceProps,
  ICreateAdviceResponse,
  IGetCompaniesProps,
  IGetKAMByCompanyProps,
  IGetKAMByCompanyResponse,
  IUpdateCompanyProfileProps,
} from './models';
import URL from './url';

export const createCompany = async (token: string, data: ICreateCompany): Promise<ICompanyUpdatedResponse> => {
  const { url, params } = URL.createCompany(token, data);
  const response = await fetch(url, params);
  return await response.json();
};

export const getCompany = async (companyId: string, token: string): Promise<ICompanyResponse> => {
  const { url, params } = URL.getCompany(companyId, token);
  const response = await fetch(url, params);
  return await response.json();
};

export const editCompany = async (
  token: string,
  id: string,
  data: IUpdateCompany
): Promise<ICompanyUpdatedResponse> => {
  const { url, params } = URL.editCompany(token, id, data);
  const response = await fetch(url, params);
  return await response.json();
};

export const getCompanies = async (args: IGetCompaniesProps): Promise<ICompaniesListResponse> => {
  const { url, params } = URL.getCompanies(args);
  const response = await fetch(url, params);
  return await response.json();
};

export const getAllCompanies = async (token: string): Promise<IAllCompaniesListResponse> => {
  const { url, params } = URL.getAllCompanies(token);
  const response = await fetch(url, params);
  return await response.json();
};

export const getCompanyAplications = async (token: string): Promise<ICompaniesListResponse> => {
  const { url, params } = URL.getCompanyAplications(token);
  const response = await fetch(url, params);
  return await response.json();
};

export const getCompanyPlan = async (token: string, companyId: string): Promise<ICompanyPlanResponse> => {
  const { url, params } = URL.getCompanyPlan(token, companyId);
  const response = await fetch(url, params);
  return await response.json();
};

export const getAssignedCompanies = async (token: string): Promise<IAssignedCompaniesListResponse> => {
  const { url, params } = URL.getAssignedCompanies(token);
  const response = await fetch(url, params);
  return await response.json();
};

export const getKAMByCompany = async (args: IGetKAMByCompanyProps): Promise<IGetKAMByCompanyResponse> => {
  const { url, params } = URL.getKAMByCompany(args);
  const response = await fetch(url, params);
  return response.json();
};

export const updateCompanyProfile = async (args: IUpdateCompanyProfileProps): Promise<ICompanyResponse> => {
  const { url, params } = URL.updateCompanyProfile(args);
  const response = await fetch(url, params);
  return response.json();
};

export const createAdvice = async (args: ICreateAdviceProps): Promise<ICreateAdviceResponse> => {
  const { url, params } = URL.createAdvice(args);
  const res = await fetch(url, params);
  return res.json();
};
