import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCandidateById, getCandidates, updateCandidateStatus } from '../../../api/candidate';
import { IUpdateCandidateStatusProps } from '../../../api/candidate/models';
import { errorProcesor } from '../../../utils/errorProcesor';
import { IFetchCandidateByIdDataResponse, IGetCandidatesDataResponse } from '../models';

export const getCandidatesAsync = createAsyncThunk(
  'candidate/getCandidates',
  async ({ token, jobOffer }: IGetCandidatesDataResponse, { rejectWithValue, dispatch }) => {
    try {
      const response = await getCandidates(token, jobOffer);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchCandidateByIdAsync = createAsyncThunk(
  'candidate/fetchCandidateById',
  async ({ token, id }: IFetchCandidateByIdDataResponse, { rejectWithValue, dispatch }) => {
    try {
      const response = await getCandidateById(token, id);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateCandidateStatusAsync = createAsyncThunk(
  'candidate/updateStatus',
  async (args: IUpdateCandidateStatusProps, { rejectWithValue, dispatch }) => {
    try {
      const response = await updateCandidateStatus(args);
      return errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
