import { ICreateCompany, IUpdateCompany } from '../../../models/ICompany';
import { IFetchOptions } from '../../../models/IFetch';
import { API_URL } from '../../../utils/constants/api';
import { getHeaders, setParamsToURL } from '../../utils';
import { ICreateAdviceProps, IGetCompaniesProps, IGetKAMByCompanyProps, IUpdateCompanyProfileProps } from '../models';

export default {
  createCompany: (token: string, companyData: ICreateCompany): IFetchOptions => ({
    url: `${API_URL}/company`,
    params: {
      method: 'POST',
      headers: getHeaders(token),
      body: JSON.stringify(companyData),
    },
  }),

  getCompany: (companyId: string, token: string): IFetchOptions => ({
    url: `${API_URL}/company/${companyId}`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),

  editCompany: (token: string, companyId: string, updatedData: IUpdateCompany): IFetchOptions => ({
    url: `${API_URL}/company/${companyId}`,
    params: {
      method: 'PUT',
      headers: getHeaders(token),
      body: JSON.stringify(updatedData),
    },
  }),

  getCompanies: ({ token, ...otherProps }: IGetCompaniesProps): IFetchOptions => ({
    url: setParamsToURL(`${API_URL}/company`, otherProps),
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),

  getAllCompanies: (token: string): IFetchOptions => ({
    url: `${API_URL}/company/all`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),

  getCompanyAplications: (token: string): IFetchOptions => ({
    url: setParamsToURL(`${API_URL}/company`, { validationStatus: 'Pendiente' }),
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),

  getCompanyPlan: (token: string, companyId: string): IFetchOptions => ({
    url: `${API_URL}/company/${companyId}/plan`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),

  getAssignedCompanies: (token: string): IFetchOptions => ({
    url: `${API_URL}/company/assigned`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),

  getKAMByCompany: ({ token, id }: IGetKAMByCompanyProps): IFetchOptions => ({
    url: `${API_URL}/company/${id}/kam`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),

  updateCompanyProfile: ({ token, id, data }: IUpdateCompanyProfileProps): IFetchOptions => ({
    url: `${API_URL}/company/${id}/profile`,
    params: {
      method: 'PUT',
      headers: getHeaders(token),
      body: JSON.stringify(data),
    },
  }),

  createAdvice: ({ token, id, data }: ICreateAdviceProps): IFetchOptions => ({
    url: `${API_URL}/company/${id}/advice`,
    params: {
      method: 'POST',
      headers: getHeaders(token),
      body: JSON.stringify(data),
    },
  }),
};
