import { IActivateAndResetProps, ISignInProps } from '../../models/IAuth';
import { IBaseFetch, IFetchResponse } from '../../models/IFetch';
import { ActivateOrReset, ILoginResponse } from './models';
import URL from './url';

export const fetchLogin = async (data: ISignInProps): Promise<ILoginResponse> => {
  const { url, params } = URL.postLogin(data);
  const response = await fetch(url, params);
  return await response.json();
};

export const forgotPassword = async (email: string): Promise<IFetchResponse<unknown>> => {
  const { url, params } = URL.forgotPassword(email);
  const response = await fetch(url, params);
  return await response.json();
};

export const activateAndResetPassword = async (
  path: ActivateOrReset,
  data: IActivateAndResetProps
): Promise<IFetchResponse<unknown>> => {
  const { url, params } = URL.activateAndResetPassword(path, data);
  const response = await fetch(url, params);
  return await response.json();
};

export const getSession = async (): Promise<ILoginResponse> => {
  const { url, params } = URL.getSession();
  const response = await fetch(url, params);
  return response.json();
};

export const logout = async (): Promise<IBaseFetch> => {
  const { url, params } = URL.logout();
  const response = await fetch(url, params);
  return response.json();
};
