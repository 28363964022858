import { useCallback, useState } from 'react';
import type { WithPagination } from '../models/IPagination';

type IUsePagianteProps = WithPagination;

export const usePaginate = ({ pages }: IUsePagianteProps) => {
  const [offset, setOffset] = useState(pages.current - 1);

  const onPageChange = useCallback((page: number) => setOffset(page), []);

  return { offset, onPageChange };
};
