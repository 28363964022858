import { useState } from 'react';
import { debounce } from '../utils/debounce';

export const useApiSearchFilter = () => {
  const [apiSearch, setApiSearch] = useState('');

  const onSearchChange = debounce(setApiSearch);

  return { apiSearch, onSearchChange };
};
