/* istanbul ignore file */
import { ThemeProvider } from 'styled-components';
import { useProfile } from '../../hooks';
import { WithChildren } from '../../models/General';
import { GlobalStyle } from '../../styles';
import { getTheme } from '../../theme';

const ThemeProviderWrapper = ({ children }: WithChildren) => {
  const { roleFlags } = useProfile();
  return (
    <ThemeProvider theme={getTheme(roleFlags.isAdmin)}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};

export default ThemeProviderWrapper;
