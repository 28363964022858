import { Suspense, lazy } from 'react';
import { useSession } from './hooks';
import LoadingSession from '../components/LoadingSession';

const AppRouter = lazy(() => import('../routers'));

const App = () => {
  const { isLoadingSession } = useSession();

  if (isLoadingSession) return <LoadingSession />;

  return (
    <Suspense fallback={<LoadingSession />}>
      <AppRouter />
    </Suspense>
  );
};

export default App;
