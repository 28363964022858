import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { toastSuccessMessage } from '../../utils/toast';
import { ICandidateState } from './models';
import { fetchCandidateByIdAsync, getCandidatesAsync, updateCandidateStatusAsync } from './thunks';
import { getCandidatesByStage } from './utils/getCandidatesByStage';

export const initialState: ICandidateState = {
  candidates: [],
  selectedCandidate: undefined,
  status: 'idle',
};

const candidateSlice = createSlice({
  name: 'candidate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //getAllCandidatesAsync
      .addCase(getCandidatesAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCandidatesAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.candidates = action.payload.data;
        state.groupByStage = getCandidatesByStage(state.candidates);
      })
      .addCase(getCandidatesAsync.rejected, (state) => {
        state.status = 'failed';
      })

      //fetchCandidateByIdAsync
      .addCase(fetchCandidateByIdAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCandidateByIdAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.selectedCandidate = action.payload.data;
      })
      .addCase(fetchCandidateByIdAsync.rejected, (state) => {
        state.status = 'failed';
      })

      //updateCandidateStatusAsync
      .addCase(updateCandidateStatusAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCandidateStatusAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.candidates = action.payload.data;

        if (state.selectedCandidate) {
          state.selectedCandidate = action.payload.data.find((el) => el._id === state.selectedCandidate?._id);
        }

        toastSuccessMessage('Estado del candidato actualizado con éxito');
      })
      .addCase(updateCandidateStatusAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const getCandidatesState = (state: RootState) => state.candidate;

export default candidateSlice.reducer;
