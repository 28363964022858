import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';
import { ProcessRoleData } from '../../models/IRole';
import { IGeneralUsers } from '../../models/IUser';
import { RootState } from '../../store';
import { toastErrorMessage, toastSuccessMessage } from '../../utils/toast';
import { fetchLoginAsync, getSessionAsync, logoutAsync } from '../authSlice/thunks';
import { updateCompanyProfileAsync } from '../companySlice/thunks';
import { editAvatarAsync, editUserProfileAsync } from '../userSlice/thunks';
import { IProfileState } from './models';

export const initialState: IProfileState = {
  user: null,
  activeRole: '',
  roleFlags: {
    isAdmin: false,
    isKam: false,
    isRecruiter: false,
    isCustomer: false,
    isTechnical: false,
  },
  companyId: null,
  company: null,
  status: 'idle',
};

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setRole: (state, action: PayloadAction<ProcessRoleData>) => {
      state.roleFlags = action.payload.roleFlags;
      state.activeRole = action.payload.activeRole;
    },
  },
  extraReducers: (builder) => {
    builder
      // logoutAsync
      .addCase(logoutAsync.fulfilled, (state) => {
        state.user = null;
        if (state.companyId) state.companyId = null;
        if (state.company) state.company = null;
      })

      //editUserProfileAsync
      .addCase(editUserProfileAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editUserProfileAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.user = action.payload.data;
        toastSuccessMessage('Usuario editado con éxito');
      })
      .addCase(editUserProfileAsync.rejected, (state, action) => {
        state.status = 'failed';
        toastErrorMessage(action.payload as string);
      })

      //editCustomerAvatarAsync
      .addCase(editAvatarAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editAvatarAsync.fulfilled, (state, action) => {
        state.status = 'updated';
        state.user = { ...state.user, avatar: action.payload.data } as IGeneralUsers;
        toastSuccessMessage('Avatar editado con éxito');
      })
      .addCase(editAvatarAsync.rejected, (state) => {
        state.status = 'failed';
      })

      //updateCompanyProfileAsync
      .addCase(updateCompanyProfileAsync.fulfilled, (state, action) => {
        state.company = action.payload.data;
        toastSuccessMessage('Datos actualizados con éxito');
      })

      // Matchers
      // login - session
      .addMatcher(isAnyOf(fetchLoginAsync.fulfilled, getSessionAsync.fulfilled), (state, action) => {
        state.user = action.payload.data.user;
        state.companyId = action.payload.data.companyId ?? null;
        state.company = action.payload.data.company ?? null;
      });
  },
});

export const { setRole } = profileSlice.actions;

export const getProfileState = (state: RootState) => state.profile;

export default profileSlice.reducer;
