import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createCustomerSkillsGroup,
  deleteCustomerSkillGroup,
  deleteCustomerTemplateSkillGroup,
  editCustomerSkillGroup,
  getCustomerSkillGroups,
  getCustomerTemplateSkillGroups,
  getOneCustomerSkillGroup,
} from '../../../api/customerSkillsGroup';
import { ICreateSkillGroup, IEditSkillGroup } from '../../../models/ISkill';
import { errorProcesor } from '../../../utils/errorProcesor';

export const createCustomerSkillsGroupAsync = createAsyncThunk(
  'customerSkillsGroup/createSkillsGroup',
  async ({ fields, token }: { fields: ICreateSkillGroup; token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await createCustomerSkillsGroup(fields, token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const getCustomerSkillGroupsAsync = createAsyncThunk(
  'customerSkillsGroup/getSkillsGroups',
  async ({ companyId, token }: { companyId: string; token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await getCustomerSkillGroups(companyId, token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const getOneCustomerSkillGroupAsync = createAsyncThunk(
  'customerSkillsGroup/getOneSkillsGroup',
  async ({ id, token }: { id: string; token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await getOneCustomerSkillGroup(id, token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const editCustomerSkillGroupAsync = createAsyncThunk(
  'customerSkillsGroup/editSkillsGroup',
  async ({ id, data, token }: { id: string; data: IEditSkillGroup; token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await editCustomerSkillGroup(id, data, token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteCustomerSkillGroupAsync = createAsyncThunk(
  'customerSkillsGroup/deleteSkillsGroup',
  async ({ id, token }: { id: string; token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await deleteCustomerSkillGroup(id, token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const getCustomerTemplateSkillGroupsAsync = createAsyncThunk(
  'customerTemplateSkillsGroup/getTemplateSkillsGroups',
  async ({ companyId, token }: { companyId: string; token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await getCustomerTemplateSkillGroups(companyId, token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteCustomerTemplateSkillGroupAsync = createAsyncThunk(
  'customerTemplateSkillsGroup/deleteTemplateSkillsGroup',
  async ({ id, token }: { id: string; token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await deleteCustomerTemplateSkillGroup(id, token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
