import {
  IFetchJobOffersWithCandidadatesAssignedProps,
  IFetchJobOffersWithCandidadatesAssignedResponse,
  IFetchWithCandidatesByCompanyProps,
  IFetchWithCandidatesByCompanyResponse,
} from './models';
import URL from './url';

export const fetchWithCandidatesByCompany = async (
  args: IFetchWithCandidatesByCompanyProps
): Promise<IFetchWithCandidatesByCompanyResponse> => {
  const { url, params } = URL.fetchWithCandidatesByCompany(args);
  const res = await fetch(url, params);
  return res.json();
};

export const fetchJobOffersWithCandidatesAssigned = async (
  args: IFetchJobOffersWithCandidadatesAssignedProps
): Promise<IFetchJobOffersWithCandidadatesAssignedResponse> => {
  const { url, params } = URL.fetchJobOffersWithCandidatesAssigned(args);
  const res = await fetch(url, params);
  return res.json();
};
