import { ICreateEvaluation } from '../../models/IEvaluations';
import { ICandidateEvaluationsResponse, IUpdateEvaluationProps } from './models';
import URL from './url';

export const getEvaluationsByCandidate = async (
  token: string,
  candidateId: string
): Promise<ICandidateEvaluationsResponse> => {
  const { url, params } = URL.getEvaluationsByCandidate(token, candidateId);
  const response = await fetch(url, params);
  return response.json();
};

export const createCandidateInterview = async (
  data: ICreateEvaluation,
  candidateId: string,
  token: string
): Promise<ICandidateEvaluationsResponse> => {
  const { url, params } = URL.createCandidateInterview(data, candidateId, token);
  const response = await fetch(url, params);
  return await response.json();
};

export const createCandidateTest = async (
  data: ICreateEvaluation,
  candidateId: string,
  token: string
): Promise<ICandidateEvaluationsResponse> => {
  const { url, params } = URL.createCandidateTest(data, candidateId, token);
  const response = await fetch(url, params);
  return await response.json();
};

export const updateEvaluation = async (args: IUpdateEvaluationProps): Promise<ICandidateEvaluationsResponse> => {
  const { url, params } = URL.updateEvaluation(args);
  const response = await fetch(url, params);
  return response.json();
};
