import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IHomeRecruiterState } from './models';
import { fetchHomeSummaryRecruiterAsync } from './thunks';

export const initialState: IHomeRecruiterState = {
  status: 'idle',
};

const homeRecruiterSlice = createSlice({
  name: 'homeRecruiter',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHomeSummaryRecruiterAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchHomeSummaryRecruiterAsync.fulfilled, (state, action) => {
        const { candidates, jobOffers } = action.payload.data;
        state.status = 'success';
        state.jobOffers = jobOffers;
        state.candidates = candidates;
      })
      .addCase(fetchHomeSummaryRecruiterAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const getHomeRecruiterState = (state: RootState) => state.homeRecruiter;

export default homeRecruiterSlice.reducer;
