import { ICreateEvaluation } from '../../../models/IEvaluations';
import { IFetchOptions } from '../../../models/IFetch';
import { API_URL } from '../../../utils/constants/api';
import { getHeaders } from '../../utils';
import { IUpdateEvaluationProps } from '../models';

export default {
  getEvaluationsByCandidate: (token: string, candidateId: string): IFetchOptions => ({
    url: `${API_URL}/candidates/${candidateId}/evaluation`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),

  createCandidateInterview: (data: ICreateEvaluation, candidateId: string, token: string): IFetchOptions => ({
    url: `${API_URL}/candidates/${candidateId}/evaluation/interview`,
    params: {
      method: 'POST',
      headers: getHeaders(token),
      body: JSON.stringify(data),
    },
  }),

  createCandidateTest: (data: ICreateEvaluation, candidateId: string, token: string): IFetchOptions => ({
    url: `${API_URL}/candidates/${candidateId}/evaluation/test${data.liable === undefined ? '/krowti' : ''}`,
    params: {
      method: 'POST',
      headers: getHeaders(token),
      body: JSON.stringify(data),
    },
  }),

  updateEvaluation: ({ token, candidateId, id, data }: IUpdateEvaluationProps): IFetchOptions => ({
    url: `${API_URL}/candidates/${candidateId}/evaluation/${id}`,
    params: {
      method: 'PUT',
      headers: getHeaders(token),
      body: JSON.stringify(data),
    },
  }),
};
