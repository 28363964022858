import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IJobOfferWithCandidates } from '../../models/IJobOffer';
import { RootState } from '../../store';
import { initialPages } from '../../utils/constants/pagination';
import { IJobOfferWithCandidatesState } from './models';
import { fetchJobOffersWithCandidatesAssignedAsync, fetchWithCandidatesByCompanyAsync } from './thunks';

export const initialState: IJobOfferWithCandidatesState = {
  status: 'idle',
  list: [],
  pages: initialPages,
};

const jobOfferWithCandidatesSlice = createSlice({
  name: 'jobOfferWithCandidates',
  initialState,
  reducers: {
    setSelectedJobOfferWithCandidates: (state, action: PayloadAction<IJobOfferWithCandidates>) => {
      state.selectedJobOfferWithCandidates = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //fetchWithCandidatesByCompanyAsync
      .addCase(fetchWithCandidatesByCompanyAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchWithCandidatesByCompanyAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.list = action.payload.data;
      })
      .addCase(fetchWithCandidatesByCompanyAsync.rejected, (state) => {
        state.status = 'failed';
      })

      //fetchJobOffersWithCandidatesAssignedAsync
      .addCase(fetchJobOffersWithCandidatesAssignedAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchJobOffersWithCandidatesAssignedAsync.fulfilled, (state, action) => {
        const { list, pages } = action.payload.data;
        state.status = 'success';
        state.list = list;
        state.pages = pages;
      })
      .addCase(fetchJobOffersWithCandidatesAssignedAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { setSelectedJobOfferWithCandidates } = jobOfferWithCandidatesSlice.actions;

export const getJobOfferWithCandidatesState = (state: RootState) => state.jobOfferWithCandidates;

export default jobOfferWithCandidatesSlice.reducer;
