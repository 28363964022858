import { Dispatch } from '@reduxjs/toolkit';
import { IFetchResponse } from '../../models/IFetch';
import { onBrokenAuthentication } from '../../redux/authSlice';
import { toastErrorMessage } from '../toast';

export const errorProcesor = <T extends IFetchResponse<U>, U = T['data']>(response: T, dispatch: Dispatch) => {
  let message = '';
  const { statusCode } = response;

  switch (statusCode) {
    case 200:
    case 201:
    case 204:
      return response;
    case 400:
    case 403:
    case 409:
    case 413:
      ({ message } = response.error as Error);
      break;
    case 401:
      message = 'Sesión expirada';
      dispatch(onBrokenAuthentication());
      break;
    case 404:
      message = 'Ruta del servicio no encontrada';
      break;
    default:
      message = 'Error en el servicio, intente más tarde o comuniquese con soporte';
      break;
  }
  toastErrorMessage(message);
  throw new Error(message);
};
