import { IFetchOptions } from '../../../models/IFetch';
import { API_URL } from '../../../utils/constants/api';
import { getHeaders, setParamsToURL } from '../../utils';
import { IUpdateCandidateStatusProps } from '../models';

export default {
  getCandidates: (token: string, jobOffer: string): IFetchOptions => ({
    url: setParamsToURL(`${API_URL}/candidates`, { jobOffer }),
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),
  getCandidateById: (token: string, id: string): IFetchOptions => ({
    url: `${API_URL}/candidates/${id}`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),

  updateCandidateStatus: ({ _id, status, token, reasonForRejection }: IUpdateCandidateStatusProps): IFetchOptions => ({
    url: `${API_URL}/candidates/${_id}/status`,
    params: {
      method: 'PUT',
      headers: getHeaders(token),
      body: JSON.stringify({ status, reasonForRejection }),
    },
  }),
};
