import { useEffect, useState } from 'react';
import { BREAKPOINTS, type IMedia } from '../utils/constants/breakpoints';

type Size = {
  width: number | undefined;
  height: number | undefined;
};

interface IWindowSizeProps {
  media?: IMedia;
}

export const useWindowSize = ({ media = 'tablet' }: IWindowSizeProps = {}) => {
  const [{ width, height }, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  });
  const isMobile = !!width && width <= BREAKPOINTS[media];

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { width, height, isMobile };
};
