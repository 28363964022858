import { IUpdateWorkOrder } from '../../models/IWorkOrder';
import {
  IUpdateAdviceProps,
  IUpdateWorkOrderResponse,
  IWorkOrderDetailResponse,
  IWorkOrderListResponse,
} from './models';
import URL from './url';

export const getWorkOrders = async (
  token: string,
  limit?: number,
  offset?: number,
  status?: string
): Promise<IWorkOrderListResponse> => {
  const { url, params } = URL.getWorkOrders(token, limit, offset, status);
  const response = await fetch(url, params);
  return await response.json();
};

export const getWorkOrderById = async (workdId: string, token: string): Promise<IWorkOrderDetailResponse> => {
  const { url, params } = URL.getWorkOrderById(workdId, token);
  const response = await fetch(url, params);
  return await response.json();
};

export const updateWorkOrders = async (
  id: string,
  token: string,
  order: IUpdateWorkOrder
): Promise<IUpdateWorkOrderResponse> => {
  const { url, params } = URL.updateWorkOrders(id, token, order);
  const response = await fetch(url, params);
  return await response.json();
};

export const updateAdvice = async (args: IUpdateAdviceProps): Promise<IWorkOrderDetailResponse> => {
  const { url, params } = URL.updateAdvice(args);
  const res = await fetch(url, params);
  return res.json();
};
