import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { toastSuccessMessage } from '../../utils/toast';
import { ICandidatesEvaluationsState } from './models';
import {
  createCandidateInterviewAsync,
  createCandidateTestAsync,
  fetchEvaluationsByCandidateAsync,
  updateEvaluationAsync,
} from './thunks';

export const initialState: ICandidatesEvaluationsState = {
  allEvaluationsListByCandidate: [],
  interviewsListByCandidate: [],
  testsListByCandidate: [],
  status: 'idle',
  error: '',
};

const candidatesEvaluationsSlice = createSlice({
  name: 'candidatesEvaluations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //fetchCandidateByIdAsync
      .addCase(fetchEvaluationsByCandidateAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEvaluationsByCandidateAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.allEvaluationsListByCandidate = action.payload.data;
      })
      .addCase(fetchEvaluationsByCandidateAsync.rejected, (state) => {
        state.status = 'failed';
      })

      //createInterviewAsync
      .addCase(createCandidateInterviewAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createCandidateInterviewAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.interviewsListByCandidate = action.payload.data;
        state.allEvaluationsListByCandidate = action.payload.data;
        toastSuccessMessage('Entrevista creada con éxito');
      })
      .addCase(createCandidateInterviewAsync.rejected, (state) => {
        state.status = 'failed';
      })

      // createTestAsync
      .addCase(createCandidateTestAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createCandidateTestAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.testsListByCandidate = action.payload.data;
        state.allEvaluationsListByCandidate = action.payload.data;
        toastSuccessMessage('Prueba creada con éxito');
      })
      .addCase(createCandidateTestAsync.rejected, (state) => {
        state.status = 'failed';
      })

      // updateEvaluationAsync
      .addCase(updateEvaluationAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateEvaluationAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.allEvaluationsListByCandidate = action.payload.data;
      })
      .addCase(updateEvaluationAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const getCandidatesEvaluationsState = (state: RootState) => state.candidatesEvaluations;

export default candidatesEvaluationsSlice.reducer;
