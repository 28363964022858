import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store/index';
import { initialPages } from '../../utils/constants/pagination';
import { toastSuccessMessage } from '../../utils/toast';
import { IWorkOrderState } from './models';
import { fetchWorkOrderAsync, getWorkOrdersAsync, updateAdviceAsync, updateWorkOrdersAsync } from './thunks';

export const initialState: IWorkOrderState = {
  pending: false,
  selectedWorkOrder: null,
  workOrderList: [],
  status: 'idle',
  pages: initialPages,
};

export const workOrderSlice = createSlice({
  name: 'completeWork',
  initialState,
  reducers: {
    setSelectedOrder(state, action: PayloadAction<IWorkOrderState['selectedWorkOrder']>) {
      state.selectedWorkOrder = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetchOrdersAsync
      .addCase(fetchWorkOrderAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchWorkOrderAsync.fulfilled, (state, action) => {
        state.workOrderList = action.payload.data.list;
        state.pages = action.payload.data.pages;
        state.status = 'success';
      })
      .addCase(fetchWorkOrderAsync.rejected, (state) => {
        state.status = 'failed';
      })
      //getWorkOrdersAsync
      .addCase(getWorkOrdersAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getWorkOrdersAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.selectedWorkOrder = action.payload.data;
      })
      .addCase(getWorkOrdersAsync.rejected, (state) => {
        state.status = 'failed';
      })
      //updateWorkOrdersAsync
      .addCase(updateWorkOrdersAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateWorkOrdersAsync.fulfilled, (state, action) => {
        const orderList = action.payload.data;
        state.selectedWorkOrder = orderList.find((order) => order._id === state.selectedWorkOrder?._id) ?? null;
        state.workOrderList = orderList;
        state.status = 'success';
        toastSuccessMessage('Orden actualizada con exito');
      })
      .addCase(updateWorkOrdersAsync.rejected, (state) => {
        state.status = 'failed';
      })

      //updateAdviceAsync
      .addCase(updateAdviceAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateAdviceAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.selectedWorkOrder = action.payload.data;
        toastSuccessMessage('Orden actualizada con exito');
      })
      .addCase(updateAdviceAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { setSelectedOrder } = workOrderSlice.actions;

export const getWorkOrderState = (state: RootState) => state.works;
export const getWorkOrderStatus = (state: RootState) => state.works.status;

export default workOrderSlice.reducer;
