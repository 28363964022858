import { IUpdateBill } from '../../../models/IBill';
import { IFetchOptions } from '../../../models/IFetch';
import { API_URL } from '../../../utils/constants/api';
import { getHeaders, setParamsToURL } from '../../utils';
import { IFetchBillParams } from '../models';

export default {
  fetchBillsByCompany: ({ token, ...restOfProps }: IFetchBillParams): IFetchOptions => ({
    url: setParamsToURL(`${API_URL}/bills`, restOfProps),
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),

  editBill: (token: string, billId: string, updatedData: IUpdateBill): IFetchOptions => ({
    url: `${API_URL}/bills/${billId}`,
    params: {
      method: 'PUT',
      headers: getHeaders(token),
      body: JSON.stringify(updatedData),
    },
  }),

  fetchAllBills: (token: string): IFetchOptions => ({
    url: `${API_URL}/bills/all`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),
};
