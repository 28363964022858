/* istanbul ignore */
import { IFetchOptions } from '../../../models/IFetch';
import { API_URL } from '../../../utils/constants/api';
import { getHeaders } from '../../utils';

export default {
  getAdministrators: (token: string): IFetchOptions => ({
    url: `${API_URL}/user/all?role=ADMIN`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),
};
