import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCompanyAplications } from '../../../api/company';
import { errorProcesor } from '../../../utils/errorProcesor';

export const getAllCompanyApplicationsAsync = createAsyncThunk(
  'company/getAllCompanyApplications',
  async ({ token }: { token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await getCompanyAplications(token);
      return errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
