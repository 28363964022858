import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAllRemunerations, getRemunerationsByRecruiter } from '../../../api/remuneration';
import { IFetchRemmunerationsByRecruiterProps } from '../../../api/remuneration/models';
import { errorProcesor } from '../../../utils/errorProcesor';

export const fetchRemunerationsAsync = createAsyncThunk(
  'remuneration/fetchRemunerationsAsync',
  async ({ token }: { token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await getAllRemunerations(token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchRemunerationsByRecruiterAsync = createAsyncThunk(
  'plans/fetchRemunerationsByRecruiterAsync',
  async (args: IFetchRemmunerationsByRecruiterProps, { rejectWithValue, dispatch }) => {
    try {
      const response = await getRemunerationsByRecruiter(args);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
