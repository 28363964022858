import { Action, combineReducers, configureStore, PreloadedState, ThunkAction } from '@reduxjs/toolkit';

import administratorReducer from '../redux/administratorSlice';
import AuthReducer from '../redux/authSlice';
import candidatesEvaluationsReducer from '../redux/candidatesEvaluations';
import CandidateReducer from '../redux/candidateSlice';
import candidatesRecruiterReducer from '../redux/candidatesRecruiterSlice';
import companyAplicationsReducer from '../redux/companyAplicationsSlice';
import CompanyReducer from '../redux/companySlice';
import ConfigReducer from '../redux/configSlice';
import CustomerBillReducer from '../redux/customerBillSlice';
import CustomerCompanyUsersSlice from '../redux/customerCompanyUsersSlice';
import customerPlanReducer from '../redux/customerPlanSlice';
import CustomerSkillsGroupSlice from '../redux/customerSkillsGroupSlice';
import homeAdminReducer from '../redux/homeAdminSlice';
import HomeKamReducer from '../redux/homeKamSlice';
import HomeRecruiterReducer from '../redux/homeRecruiterSlice';
import jobOffersOpenReducer from '../redux/jobOffersOpenSlice';
import jobOffersReducer from '../redux/jobOffersSlice';
import JobOfferWithCandidatesReducer from '../redux/jobOfferWithCandidatesSlice';
import PlansReducer from '../redux/plansSlice';
import PopUpReducer from '../redux/popUpSlice';
import ProfileReducer from '../redux/profileSlice';
import remunerationReducer from '../redux/remunerationSlice';
import ServiceReducer from '../redux/serviceSlice';
import userActivationReducer from '../redux/userActivationSlice';
import userReducer from '../redux/userSlice';
import workReducer from '../redux/workOrderSlice';
import candidatesOffersReducer from '../redux/candidatesOffersSlice';

export const rootReducer = combineReducers({
  auth: AuthReducer,
  popUp: PopUpReducer,
  profile: ProfileReducer,
  user: userReducer,
  candidate: CandidateReducer,
  company: CompanyReducer,
  companyApplications: companyAplicationsReducer,
  jobOffers: jobOffersReducer,
  jobOffersOpen: jobOffersOpenReducer,
  customerPlan: customerPlanReducer,
  customerBill: CustomerBillReducer,
  customerSkillsGroup: CustomerSkillsGroupSlice,
  customerCompanyUsers: CustomerCompanyUsersSlice,
  config: ConfigReducer,
  works: workReducer,
  administrator: administratorReducer,
  userActivation: userActivationReducer,
  service: ServiceReducer,
  plans: PlansReducer,
  remunerations: remunerationReducer,
  homeAdmin: homeAdminReducer,
  candidates: candidatesRecruiterReducer,
  homeKam: HomeKamReducer,
  homeRecruiter: HomeRecruiterReducer,
  candidatesEvaluations: candidatesEvaluationsReducer,
  jobOfferWithCandidates: JobOfferWithCandidatesReducer,
  candidatesOffers: candidatesOffersReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type InitialRootState = PreloadedState<RootState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
