import { API_URL } from '../../../utils/constants/api';
import { IFetchOptions } from '../../../models/IFetch';
import { getHeaders, setParamsToURL } from '../../utils';

export default {
  getCandidatesRecruiter: (token: string, jobOffer?: string, registeredBy?: string): IFetchOptions => ({
    url: setParamsToURL(`${API_URL}/candidates`, { jobOffer, registeredBy }),
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),

  createCandidateRecruiter: (data: FormData, token: string): IFetchOptions => ({
    url: `${API_URL}/candidates`,
    params: {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: data,
    },
  }),
};
