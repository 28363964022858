import { createAsyncThunk } from '@reduxjs/toolkit';
import { getWorkOrderById, getWorkOrders, updateAdvice, updateWorkOrders } from '../../../api/workOrders';
import { IUpdateAdviceProps } from '../../../api/workOrders/models';
import { IUpdateWorkOrder } from '../../../models/IWorkOrder';
import { errorProcesor } from '../../../utils/errorProcesor';

export const getWorkOrdersAsync = createAsyncThunk(
  'workOrders/getWorkOrders',
  async ({ workOrderId, token }: { workOrderId: string; token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await getWorkOrderById(workOrderId, token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchWorkOrderAsync = createAsyncThunk(
  'workOrders/fetchWorkOrderAsync',
  async (
    { token, limit, offset, status }: { token: string; limit?: number; offset: number; status?: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await getWorkOrders(token, limit, offset, status);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateWorkOrdersAsync = createAsyncThunk(
  'workOrders/updateWorkOrdersAsync',
  async (
    { token, order, id }: { token: string; order: IUpdateWorkOrder; id: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await updateWorkOrders(id, token, order);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateAdviceAsync = createAsyncThunk(
  'workOrders/update/advice',
  async (args: IUpdateAdviceProps, { rejectWithValue, dispatch }) => {
    try {
      const response = await updateAdvice(args);
      return errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
