import { createAsyncThunk } from '@reduxjs/toolkit';
import { errorProcesor } from '../../../utils/errorProcesor';
import { createCandidateRecruiter, getCandidatesRecruiter } from '../../../api/candidatesRecruiter';

export const fetchCandidatesRecruiterAsync = createAsyncThunk(
  'candidates/fetchCandidatesRecruiter',
  async ({ token, jobOfferId }: { token: string; jobOfferId?: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await getCandidatesRecruiter(token, jobOfferId);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchCandidatesRecruiterByRecruiterAsync = createAsyncThunk(
  'candidates/fetchCandidatesRecruiterByRecruiter',
  async (
    { token, jobOfferId, recruiterId }: { token: string; jobOfferId?: string; recruiterId?: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await getCandidatesRecruiter(token, jobOfferId, recruiterId);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const createCandidateRecruiterAsync = createAsyncThunk(
  'candidates/createCandidatesRecruiter',
  async ({ data, token }: { data: FormData; token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await createCandidateRecruiter(data, token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
