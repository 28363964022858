import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createJobOffer,
  editJobOffer,
  getJobOfferById,
  getJobOffersByCustomer,
  updateJobOfferSuspension,
} from '../../../api/jobOffers';
import { IUpdateJobOfferSuspensionProps } from '../../../api/jobOffers/models';
import { errorProcesor } from '../../../utils/errorProcesor';
import {
  ICreateJobOfferAsyncProps,
  IEditJobOfferAsyncProps,
  IFetchJobOffersByCustomerProps,
  IJobOfferDataResponse,
} from '../models';

export const fetchJobOfferByCustomerAsync = createAsyncThunk(
  'jobOffer/fetchByCustomer',
  async ({ token, companyId }: IFetchJobOffersByCustomerProps, { rejectWithValue, dispatch }) => {
    try {
      const response = await getJobOffersByCustomer(token, companyId);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchJobOfferByIdAsync = createAsyncThunk(
  'jobOffer/fetchById',
  async ({ token, id }: IJobOfferDataResponse, { rejectWithValue, dispatch }) => {
    try {
      const response = await getJobOfferById(token, id);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const createJobOfferAsync = createAsyncThunk(
  'jobOffer/create',
  async (params: ICreateJobOfferAsyncProps, { rejectWithValue, dispatch }) => {
    try {
      const response = await createJobOffer(params);
      return errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const editJobOfferAsync = createAsyncThunk(
  'jobOffer/edit',
  async (params: IEditJobOfferAsyncProps, { rejectWithValue, dispatch }) => {
    try {
      const response = await editJobOffer(params);
      return errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateJobOfferSuspensionAsync = createAsyncThunk(
  'jobOffer/suspension',
  async (args: IUpdateJobOfferSuspensionProps, { rejectWithValue, dispatch }) => {
    try {
      const response = await updateJobOfferSuspension(args);
      return errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
