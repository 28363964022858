import { createSlice } from '@reduxjs/toolkit';
import { IRemunerationState } from './models';
import { fetchRemunerationsAsync, fetchRemunerationsByRecruiterAsync } from './thunks';
import { RootState } from '../../store/index';

export const initialState: IRemunerationState = {
  status: 'idle',
  remunerationList: [],
};

export const remunerationSlice = createSlice({
  name: 'remuneration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetchRemunerationsAsync
      .addCase(fetchRemunerationsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRemunerationsAsync.rejected, (state) => {
        state.status = 'failed';
        state.remunerationList = [];
      })
      .addCase(fetchRemunerationsAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.remunerationList = action.payload.data;
      })

      // fetchRemunerationsByRecruiterAsync
      .addCase(fetchRemunerationsByRecruiterAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchRemunerationsByRecruiterAsync.rejected, (state) => {
        state.status = 'failed';
        state.remunerationList = [];
      })
      .addCase(fetchRemunerationsByRecruiterAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.remunerationList = action.payload.data;
      });
  },
});

export const getAllRemunerations = (state: RootState) => state.remunerations;
export const getRemunerationsStatus = (state: RootState) => state.remunerations.status;

export default remunerationSlice.reducer;
