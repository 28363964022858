import { ICreateJobOfferAsyncProps, IEditJobOfferAsyncProps } from '../../redux/jobOffersSlice/models';
import {
  IJobOfferByIdResponse,
  IJobOfferListResponse,
  IJobOfferResponse,
  IUpdateJobOfferSuspensionProps,
} from './models';
import URL from './url';

export const getJobOffersByCustomer = async (token: string, companyId: string): Promise<IJobOfferResponse> => {
  const { url, params } = URL.getJobOffersByCustomer(token, companyId);
  const response = await fetch(url, params);
  return response.json();
};

export const getJobOfferById = async (token: string, id: string): Promise<IJobOfferByIdResponse> => {
  const { url, params } = URL.getJobOfferById(token, id);
  const response = await fetch(url, params);
  return response.json();
};

export const createJobOffer = async (data: ICreateJobOfferAsyncProps): Promise<IJobOfferListResponse> => {
  const { url, params } = URL.createJobOffer(data);
  const response = await fetch(url, params);
  return response.json();
};

export const editJobOffer = async (data: IEditJobOfferAsyncProps): Promise<IJobOfferListResponse> => {
  const { url, params } = URL.editJobOffer(data);
  const response = await fetch(url, params);
  return response.json();
};

export const updateJobOfferSuspension = async (
  args: IUpdateJobOfferSuspensionProps
): Promise<IJobOfferListResponse> => {
  const { url, params } = URL.updateSuspension(args);
  const response = await fetch(url, params);
  return response.json();
};
