import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IPopUpContent, IPopUpState } from './models';

export const initialState: IPopUpState = {
  isOpen: false,
  content: null,
  popUpContext: null,
};

const popUpSlice = createSlice({
  name: 'popUp',
  initialState,
  reducers: {
    openWithContent: (state, action: PayloadAction<IPopUpContent>) => {
      state.isOpen = true;
      state.content = action.payload;
    },
    onClose: () => initialState,
  },
});

export const { openWithContent, onClose } = popUpSlice.actions;

export const getPopUpState = (state: RootState) => state.popUp;

export default popUpSlice.reducer;
