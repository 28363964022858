import { ProcessRoleData } from '../models/IRole';
import { getProfileState, setRole } from '../redux/profileSlice';
import { useAppDispatch, useAppSelector } from '../store/hooks';

export const useProfile = () => {
  const dispatch = useAppDispatch();
  const { user, activeRole, roleFlags } = useAppSelector(getProfileState);
  const isKrowTIUser = roleFlags.isAdmin || roleFlags.isKam || roleFlags.isRecruiter;

  const setRoleData = (roleData: ProcessRoleData) => dispatch(setRole(roleData));

  return { user, roleFlags, activeRole, isKrowTIUser, setRoleData };
};
