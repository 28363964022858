import { IFetchOptions } from '../../../models/IFetch';
import { API_URL } from '../../../utils/constants/api';
import { getHeaders, setParamsToURL } from '../../utils';
import { IFetchRemmunerationsByRecruiterProps } from '../models';

export default {
  getAllRemunerations: (token: string): IFetchOptions => ({
    url: `${API_URL}/remuneration/all`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),

  getRemunerationsByRecruiter: ({
    token,
    recruiterId,
    ...restOfProps
  }: IFetchRemmunerationsByRecruiterProps): IFetchOptions => ({
    url: setParamsToURL(`${API_URL}/remuneration/all/${recruiterId}`, restOfProps),
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),
};
