import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createPlan,
  deletePlan,
  getOnePlan,
  getPlans,
  getPlansActive,
  updateOrderPreference,
  updatePlan,
} from '../../../api/plans';
import { IUpdateOrderPreferenceProps } from '../../../api/plans/models';
import { ICreatePlan, IUpdatePlan } from '../../../models/IPlan';
import { errorProcesor } from '../../../utils/errorProcesor';

export const createPlanAsync = createAsyncThunk(
  'plans/createPlan',
  async ({ fields, token }: { fields: ICreatePlan; token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await createPlan(fields, token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const getOnePlanAsync = createAsyncThunk(
  'plans/getOnePlan',
  async ({ planId, token }: { planId: string; token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await getOnePlan(planId, token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const getPlansAsync = createAsyncThunk(
  'plans/getPlans',
  async ({ token }: { token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await getPlans(token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updatePlanAsync = createAsyncThunk(
  'plans/updatePlan',
  async ({ token, id, data }: { token: string; id: string; data: IUpdatePlan }, { rejectWithValue, dispatch }) => {
    try {
      const response = await updatePlan(token, id, data);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deactivatePlanAsync = createAsyncThunk(
  'plans/deactivatePlan',
  async ({ token, id, data }: { token: string; id: string; data: IUpdatePlan }, { rejectWithValue, dispatch }) => {
    try {
      const response = await updatePlan(token, id, data);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deletePlanAsync = createAsyncThunk(
  'plans/deletePlan',
  async ({ planId, token }: { planId: string; token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await deletePlan(planId, token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const getPlansActiveAsync = createAsyncThunk(
  'plans/getPlansActive',
  async ({ token }: { token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await getPlansActive(token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateOrderPreferenceAsync = createAsyncThunk(
  'plans/update/order',
  async (args: IUpdateOrderPreferenceProps, { rejectWithValue, dispatch }) => {
    try {
      const response = await updateOrderPreference(args);
      return errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
