import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createCandidateInterview,
  createCandidateTest,
  getEvaluationsByCandidate,
  updateEvaluation,
} from '../../../api/candidatesEvaluations';
import { IUpdateEvaluationProps } from '../../../api/candidatesEvaluations/models';
import { ICreateEvaluation } from '../../../models/IEvaluations';
import { errorProcesor } from '../../../utils/errorProcesor';
import { IFetchEvaluationsByCandidateDataResponse } from '../models';

export const createCandidateInterviewAsync = createAsyncThunk(
  'candidatesEvaluations/createCandidateInterview',
  async (
    { data, candidateId, token }: { data: ICreateEvaluation; candidateId: string; token: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await createCandidateInterview(data, candidateId, token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const createCandidateTestAsync = createAsyncThunk(
  'candidatesEvaluations/createCandidateTest',
  async (
    { data, candidateId, token }: { data: ICreateEvaluation; candidateId: string; token: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await createCandidateTest(data, candidateId, token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchEvaluationsByCandidateAsync = createAsyncThunk(
  'candidatesEvaluations/fetchEvaluationsByCandidate',
  async ({ token, candidateId }: IFetchEvaluationsByCandidateDataResponse, { rejectWithValue, dispatch }) => {
    try {
      const response = await getEvaluationsByCandidate(token, candidateId);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const updateEvaluationAsync = createAsyncThunk(
  'candidatesEvaluations/update',
  async (args: IUpdateEvaluationProps, { rejectWithValue, dispatch }) => {
    try {
      const response = await updateEvaluation(args);
      return errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
