import { IFetchOptions } from '../../../models/IFetch';
import { getHeaders } from '../../utils';
import { API_URL } from '../../../utils/constants/api';
import { ICreateOffer, IEditOffer } from '../../../models/ICandidateOffer';

export default {
  getOffersByCandidate: (token: string, candidateId: string): IFetchOptions => ({
    url: `${API_URL}/candidates/${candidateId}/offer`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),

  createOffer: (data: ICreateOffer, candidateId: string, token: string): IFetchOptions => ({
    url: `${API_URL}/candidates/${candidateId}/offer`,
    params: {
      method: 'POST',
      headers: getHeaders(token),
      body: JSON.stringify(data),
    },
  }),

  editOffer: (data: IEditOffer, offerId: string, candidateId: string, token: string): IFetchOptions => ({
    url: `${API_URL}/candidates/${candidateId}/offer/${offerId}`,
    params: {
      method: 'PUT',
      headers: getHeaders(token),
      body: JSON.stringify(data),
    },
  }),
};
