/* istanbul ignore file */
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import App from './app';
import StoreProvider from './providers/StoreProvider';
import ThemeProviderWrapper from './providers/ThemeProvider';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StoreProvider>
    <ThemeProviderWrapper>
      <App />
      <ToastContainer />
    </ThemeProviderWrapper>
  </StoreProvider>
);
