import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAdministrators } from '../../../api/admins';
import { errorProcesor } from '../../../utils/errorProcesor';

// Acción asincrona para obtener usuarios con rol ADMIN
export const getAdministratorsAsync = createAsyncThunk(
  'admin/getAdministrators',
  async ({ token }: { token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await getAdministrators(token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
