import { createAsyncThunk } from '@reduxjs/toolkit';
import { activateAndResetPassword, fetchLogin, forgotPassword, getSession, logout } from '../../../api/auth';
import { ISignInProps } from '../../../models/IAuth';
import { HttpStatus } from '../../../models/IFetch';
import { errorProcesor } from '../../../utils/errorProcesor';
import { ActivateAndResetAsyncProps } from '../models';

export const fetchLoginAsync = createAsyncThunk(
  'auth/fetchLoginAsync',
  async (fields: ISignInProps, { rejectWithValue }) => {
    try {
      const data = await fetchLogin(fields);
      if (data.statusCode !== 200) throw new Error();
      return data;
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const forgotPasswordAsync = createAsyncThunk(
  'auth/forgotPasswordAsync',
  async ({ email }: Pick<ISignInProps, 'email'>, { rejectWithValue, dispatch }) => {
    try {
      const data = await forgotPassword(email);
      return errorProcesor(data, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const activateAndResetPasswordAsync = createAsyncThunk(
  'auth/activateAndReset',
  async ({ path, ...rest }: ActivateAndResetAsyncProps, { rejectWithValue, dispatch }) => {
    try {
      const res = await activateAndResetPassword(path, rest);
      return errorProcesor(res, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const getSessionAsync = createAsyncThunk('auth/session', async (_, { rejectWithValue }) => {
  try {
    const res = await getSession();
    if (res.statusCode !== HttpStatus.OK) throw new Error();
    return res;
  } catch (error) {
    return rejectWithValue((error as Error).message);
  }
});

export const logoutAsync = createAsyncThunk('auth/logout', async (_, { rejectWithValue }) => {
  try {
    const res = await logout();
    if (res.statusCode !== HttpStatus.OK) throw new Error();
    return res;
  } catch (error) {
    return rejectWithValue((error as Error).message);
  }
});
