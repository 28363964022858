import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { toastSuccessMessage } from '../../utils/toast';
import { ICustomerCompanyUsersState } from './models';
import {
  createCustomerCompanyUserAsync,
  fetchUsersByCompanyAsync,
  getOneCustomerCompanyUserAsync,
  updateCompanyUserAsync,
} from './thunks';

export const initialState: ICustomerCompanyUsersState = {
  companyId: '',
  users: [],
  status: 'idle',
  selectedUser: null,
  error: '',
};

const CustomerCompanyUsersSlice = createSlice({
  name: 'customerCompanyUsers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //getCustomerCompanyUsersAsync
      .addCase(fetchUsersByCompanyAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsersByCompanyAsync.fulfilled, (state, action) => {
        if (action.payload) state.users = action.payload.data;
        state.status = 'success';
      })
      .addCase(fetchUsersByCompanyAsync.rejected, (state) => {
        state.status = 'failed';
      })

      //getOneCustomerCompanyUserAsync
      .addCase(getOneCustomerCompanyUserAsync.pending, (state) => {
        state.status = 'loading';
      })

      .addCase(getOneCustomerCompanyUserAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.selectedUser = action.payload.data;
      })

      .addCase(getOneCustomerCompanyUserAsync.rejected, (state) => {
        state.status = 'failed';
      })

      //createCustomerCompanyUserAsync
      .addCase(createCustomerCompanyUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createCustomerCompanyUserAsync.fulfilled, (state, action) => {
        if (action.payload) state.users = action.payload.data.list;
        state.status = 'success';
        toastSuccessMessage('Se ha creado el perfil correctamente');
      })
      .addCase(createCustomerCompanyUserAsync.rejected, (state) => {
        state.status = 'failed';
      })

      //updateCustomerCompanyUserAsync
      .addCase(updateCompanyUserAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCompanyUserAsync.fulfilled, (state, action) => {
        if (action.payload) state.users = action.payload.data.list;
        state.status = 'success';
        toastSuccessMessage('Se ha editado el perfil correctamente');
      })
      .addCase(updateCompanyUserAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const getCustomerCompanyUsersState = (state: RootState) => state.customerCompanyUsers;
export const getCustomerCompanyUsersStatus = (state: RootState) => state.customerCompanyUsers.status;

export default CustomerCompanyUsersSlice.reducer;
