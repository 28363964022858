import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  createUser,
  editUser,
  fetchUsersAll,
  getUser,
  getUsersAdmin,
  updateCustomerAvatar,
  updateUserProfile,
} from '../../../api/users';
import { IFetchUsersAllProps } from '../../../api/users/models';
import { IToken } from '../../../models/IToken';
import { IUpdateUser } from '../../../models/IUser';
import { errorProcesor } from '../../../utils/errorProcesor';
import { ICreateUserAsyncProps, IEditUserAsyncProps } from '../models';

interface IGetUserAsyncProp extends IToken {
  userId: string;
}

// Acción asíncrona para obtener todos los usuarios
export const getUsersAdminAsync = createAsyncThunk(
  'user/getAllUsers',
  async ({ token }: { token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await getUsersAdmin(token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

// Acción asíncrona para crear usuario
export const createUserAsync = createAsyncThunk(
  'user/createUser',
  async ({ fields, token, role }: ICreateUserAsyncProps, { rejectWithValue, dispatch }) => {
    try {
      const response = await createUser(fields, token, role);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
// Acción asíncrona para obtener un usuario
export const getUserAsync = createAsyncThunk(
  'user/getUser',
  async ({ userId, token }: IGetUserAsyncProp, { rejectWithValue, dispatch }) => {
    try {
      const response = await getUser(userId, token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
// Acción asíncrona para editar el usuario
export const editUserAsync = createAsyncThunk(
  'user/editUser',
  async ({ userId, fields, token }: IEditUserAsyncProps, { rejectWithValue, dispatch }) => {
    try {
      const response = await editUser(userId, fields, token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

//Accion asincronica para editar el usuario
export const editUserProfileAsync = createAsyncThunk(
  `user/editUserProfile`,
  async (
    { token, userId, data }: { token: string; userId: string; data: IUpdateUser },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await updateUserProfile(token, userId, data);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

//Accion asincronica para editar el avatar
export const editAvatarAsync = createAsyncThunk(
  `user/editCustomerAvatar`,
  async ({ token, userId, data }: { token: string; userId: string; data: FormData }, { rejectWithValue, dispatch }) => {
    try {
      const response = await updateCustomerAvatar(token, userId, data);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const fetchUsersAllAsync = createAsyncThunk(
  'user/fetch/all',
  async (args: IFetchUsersAllProps, { rejectWithValue, dispatch }) => {
    try {
      const response = await fetchUsersAll(args);
      return errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
