import { useNavigate } from 'react-router-dom';
import { recoverySuccess } from '../features/Auth/RecoveryPassword/constants';
import { ISignInProps } from '../models/IAuth';
import { getAuthState, getAuthenticationError, getAuthenticationStatus, removeRecoveryState } from '../redux/authSlice';
import { fetchLoginAsync, forgotPasswordAsync, logoutAsync } from '../redux/authSlice/thunks';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { usePopUp } from './usePopUp';

export const useAuth = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const authStatus = useAppSelector(getAuthenticationStatus);
  const authError = useAppSelector(getAuthenticationError);
  const { recovery } = useAppSelector(getAuthState);
  const { openPopUp } = usePopUp();
  const isLoading = authStatus === 'loading';

  const fetchLogin = (data: ISignInProps) => dispatch(fetchLoginAsync(data));

  const forgotPassword = async (data: Pick<ISignInProps, 'email'>) => {
    const res = await dispatch(forgotPasswordAsync(data)).unwrap();
    if (res.statusCode !== 201) return;
    openPopUp(recoverySuccess);
  };

  const handleLogout = () => dispatch(logoutAsync());

  const handleRecoverySuccess = () => {
    dispatch(removeRecoveryState());
    navigate('/iniciar-sesion');
  };

  return { authError, isLoading, recovery, fetchLogin, forgotPassword, handleLogout, handleRecoverySuccess };
};
