import styled from 'styled-components';

export const LoadingStyled = styled.div`
  height: 40px;
  width: 40px;
  border: 5px solid ${({ theme }) => theme.primaryColor};
  border-bottom-color: transparent;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  display: inline-block;
  text-align: center;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SpinnerOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
`;

export const GraySpinner = styled(LoadingStyled)`
  filter: grayscale(100%);
`;
