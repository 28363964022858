import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IJobOffer } from '../../models/IJobOffer';
import { RootState } from '../../store/index';
import { toastSuccessMessage } from '../../utils/toast';
import { fetchCustomerPlanAsync } from '../customerPlanSlice/thunks';
import { IJobOfferState } from './models';
import {
  createJobOfferAsync,
  editJobOfferAsync,
  fetchJobOfferByCustomerAsync,
  fetchJobOfferByIdAsync,
  updateJobOfferSuspensionAsync,
} from './thunks';

export const initialState: IJobOfferState = {
  jobOffer: undefined,
  jobOfferList: [],
  status: 'idle',
  planState: 'idle',
};

export const jobOfferSlice = createSlice({
  name: 'jobOffer',
  initialState,
  reducers: {
    setSelectedJobOffer: (state, action: PayloadAction<IJobOffer>) => {
      state.selectedJobOffer = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //fetchJobOfferByCompanyAsync
      .addCase(fetchJobOfferByCustomerAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchJobOfferByCustomerAsync.rejected, (state) => {
        state.status = 'failed';
        state.jobOfferList = [];
      })
      .addCase(fetchJobOfferByCustomerAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.jobOfferList = action.payload.data;
        if (!state.selectedJobOffer) state.selectedJobOffer = action.payload.data.at(0);
      })

      //fetchJobOfferByIdAsync
      .addCase(fetchJobOfferByIdAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchJobOfferByIdAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchJobOfferByIdAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.jobOffer = action.payload.data;
        state.selectedJobOffer = action.payload.data;
      })

      //fetchCustomerPlanAsync
      .addCase(fetchCustomerPlanAsync.fulfilled, (state) => {
        state.planState = 'success';
      })

      //createJobOfferAsync
      .addCase(createJobOfferAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createJobOfferAsync.fulfilled, (state, action) => {
        const { list } = action.payload.data;
        state.status = 'success';
        state.jobOfferList = list;
        state.selectedJobOffer = list.at(0);
        toastSuccessMessage('Proceso de búsqueda creado con éxito');
      })
      .addCase(createJobOfferAsync.rejected, (state) => {
        state.status = 'failed';
      })

      //editJobOfferAsync
      .addCase(editJobOfferAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editJobOfferAsync.fulfilled, (state, action) => {
        const { list } = action.payload.data;
        state.status = 'success';
        state.jobOfferList = list;
        state.selectedJobOffer = list.find((el) => el._id === state.selectedJobOffer?._id) ?? list.at(0);
        toastSuccessMessage('Cambios guardados');
      })
      .addCase(editJobOfferAsync.rejected, (state) => {
        state.status = 'failed';
      })

      //updateJobOfferSuspensionAsync
      .addCase(updateJobOfferSuspensionAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateJobOfferSuspensionAsync.fulfilled, (state, action) => {
        const { list } = action.payload.data;
        state.status = 'success';
        state.jobOfferList = list;
        state.selectedJobOffer = list.find((el) => el._id === state.selectedJobOffer?._id);
      })
      .addCase(updateJobOfferSuspensionAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { setSelectedJobOffer } = jobOfferSlice.actions;

export const getJobOffersState = (state: RootState) => state.jobOffers;
export const getJobOffersStatus = (state: RootState) => state.jobOffers.status;

export default jobOfferSlice.reducer;
