import { useEffect } from 'react';
import { getAuthenticatedToken } from '../redux/authSlice';
import { getCompaniesList } from '../redux/companySlice';
import { getAllCompaniesAsync } from '../redux/companySlice/thunks';
import { useAppDispatch, useAppSelector } from '../store/hooks';

export const useGetAllCompanies = () => {
  const dispatch = useAppDispatch();
  const token = useAppSelector(getAuthenticatedToken);
  const list = useAppSelector(getCompaniesList);

  useEffect(() => {
    dispatch(getAllCompaniesAsync({ token }));
  }, []);

  const multiselectOptions = list.map(({ name, _id }) => ({ label: name, value: _id }));

  return { multiselectOptions };
};
