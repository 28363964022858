import styled from 'styled-components';

export const ScrollBar = styled.div`
  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.color.grayTwo};
    border-radius: 9999px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.color.grayThree};
  }

  ::-webkit-scrollbar-track-piece {
    background: transparent;
    border-radius: 9999px;
  }
`;
