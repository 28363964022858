export const tooltip = {
  top: {
    top: 'auto',
    bottom: '100%',
    left: 'calc(50% - 12px)',
    right: 'auto',
    afTop: '100%',
    afRight: 'calc(50% - 5px)',
  },
  bottom: {
    top: '100%',
    bottom: 'auto',
    left: 'calc(50% - 12px)',
    right: 'auto',
    afTop: '0',
    afRight: 'calc(50% - 5px)',
  },
  right: {
    top: 'calc(50% - 8px)',
    bottom: 'auto',
    left: '100%',
    right: 'auto',
    afTop: 'calc(50% - 5px)',
    afRight: '100%',
  },
  left: {
    top: 'calc(50% - 8px)',
    bottom: 'auto',
    left: 'auto',
    right: '100%',
    afTop: 'calc(50% - 5px)',
    afRight: '0',
  },
};
