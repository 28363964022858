/* istanbul ignore */
import { IFetchOptions } from '../../../models/IFetch';
import { IAdmin, ICreateAdmin, ICreateKam, ICreateRecruiter, IUpdateUser } from '../../../models/IUser';
import { API_URL } from '../../../utils/constants/api';
import { getHeaders, setParamsToURL } from '../../utils';
import { IFetchUsersAllProps } from '../models';

export default {
  createUser: (userData: ICreateAdmin | ICreateKam | ICreateRecruiter, token: string, role: string): IFetchOptions => ({
    url: `${API_URL}/user/${role}/register`,
    params: {
      method: 'POST',
      headers: getHeaders(token),
      body: JSON.stringify(userData),
    },
  }),

  getUser: (userId: string, token: string): IFetchOptions => ({
    url: `${API_URL}/user/${userId}`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),
  editUser: (userId: string, updatedData: Partial<IAdmin>, token: string): IFetchOptions => ({
    url: `${API_URL}/user/${userId}`,
    params: {
      method: 'PUT',
      headers: getHeaders(token),
      body: JSON.stringify(updatedData),
    },
  }),

  getUsersAdmin: (token) => ({
    url: `${API_URL}/user/all`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),

  editUserProfile: (token: string, userId: string, updateData: IUpdateUser): IFetchOptions => ({
    url: `${API_URL}/user/${userId}/profile`,
    params: {
      method: 'PUT',
      headers: getHeaders(token),
      body: JSON.stringify(updateData),
    },
  }),

  editAvatar: (token: string, userId: string, updatedData: FormData): IFetchOptions => ({
    url: `${API_URL}/user/${userId}/avatar`,
    params: {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: updatedData,
    },
  }),

  getProfile: (token: string): IFetchOptions => ({
    url: `${API_URL}/user/profile`,
    params: {
      method: 'GET',
      headers: getHeaders(token),
    },
  }),

  fetchUsersAll: ({ token, role }: IFetchUsersAllProps): IFetchOptions => ({
    url: setParamsToURL(`${API_URL}/user/all`, { role }),
    params: {
      headers: getHeaders(token),
    },
  }),
};
