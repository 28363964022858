import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { toastSuccessMessage } from '../../utils/toast';
import { IPlanState } from './models';
import {
  createPlanAsync,
  deactivatePlanAsync,
  deletePlanAsync,
  getOnePlanAsync,
  getPlansActiveAsync,
  getPlansAsync,
  updateOrderPreferenceAsync,
  updatePlanAsync,
} from './thunks';

export const initialState: IPlanState = {
  plans: [],
  plansActive: [],
  status: 'idle',
  error: null,
  selectedPlan: null,
};

const planSlice = createSlice({
  name: 'plans',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //getPlans async
    builder
      .addCase(getPlansAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPlansAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.plans = action.payload.data;
      })
      .addCase(getPlansAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      //getOnePlanAsync
      .addCase(getOnePlanAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getOnePlanAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.selectedPlan = action.payload.data;
      })
      .addCase(getOnePlanAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      //getPlansActive async
      .addCase(getPlansActiveAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getPlansActiveAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.plansActive = action.payload.data;
      })
      .addCase(getPlansActiveAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      //createPlanAsync
      .addCase(createPlanAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createPlanAsync.fulfilled, (state, action) => {
        state.plans = action.payload.data.list;
        state.status = 'success';
        toastSuccessMessage('Plan creado con éxito');
      })
      .addCase(createPlanAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      //updatePlanAsync
      .addCase(updatePlanAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updatePlanAsync.fulfilled, (state, action) => {
        state.plans = action.payload.data.list;
        state.status = 'success';
        toastSuccessMessage('Plan actualizado con éxito');
      })
      .addCase(updatePlanAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      //deactivatePlanAsync
      .addCase(deactivatePlanAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deactivatePlanAsync.fulfilled, (state, action) => {
        state.plans = action.payload.data.list;
        state.status = 'success';
      })
      .addCase(deactivatePlanAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      //deletePlanAsync
      .addCase(deletePlanAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deletePlanAsync.fulfilled, (state, action) => {
        state.plans = action.payload.data;
        state.status = 'success';
        toastSuccessMessage('Plan eliminado con éxito');
      })
      .addCase(deletePlanAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      // updateOrderPreferenceAsync
      .addCase(updateOrderPreferenceAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateOrderPreferenceAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.plans = action.payload.data;
      })
      .addCase(updateOrderPreferenceAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const getPlansList = (state: RootState) => state.plans.plans;
export const getPlansActiveList = (state: RootState) => state.plans.plansActive;
export const getStatusFetchPlans = (state: RootState) => state.plans.status;
export const getPlansState = (state: RootState) => state.plans;

export default planSlice.reducer;
