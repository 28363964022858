import { createAsyncThunk } from '@reduxjs/toolkit';
import { createService, getService, editService, getServices, deleteService } from '../../../api/services';
import { ICreateService } from '../../../models/IService';
import { errorProcesor } from '../../../utils/errorProcesor';

export const createServiceAsync = createAsyncThunk(
  'service/createService',
  async ({ fields, token }: { fields: ICreateService; token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await createService(fields, token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const getServiceAsync = createAsyncThunk(
  'service/getService',
  async ({ serviceId, token }: { serviceId: string; token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await getService(serviceId, token);
      return errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const editServiceAsync = createAsyncThunk(
  'service/editService',
  async (
    { serviceId, data, token }: { serviceId: string; data: ICreateService; token: string },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await editService(serviceId, data, token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const getServicesAsync = createAsyncThunk(
  'service/getServices',
  async ({ token }: { token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await getServices(token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);

export const deleteServiceAsync = createAsyncThunk(
  'service/deleteService',
  async ({ serviceId, token }: { serviceId: string; token: string }, { rejectWithValue, dispatch }) => {
    try {
      const response = await deleteService(serviceId, token);
      return await errorProcesor(response, dispatch);
    } catch (error) {
      return rejectWithValue((error as Error).message);
    }
  }
);
