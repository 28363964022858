import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { IConfigState } from './models';
import { fetchTransferDataAsync } from './thunks';

export const initialState: IConfigState = {
  transferData: {
    bank: '',
    name: '',
    rut: '',
    accountType: '',
    accountNumber: '',
    email: '',
    comment: '',
  },
  status: 'idle',
};

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // fetchTransferDataAsync
      .addCase(fetchTransferDataAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTransferDataAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.transferData = action.payload.data;
      })
      .addCase(fetchTransferDataAsync.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const getTransferData = (state: RootState) => state.config.transferData;
export const getTransferDataStatus = (state: RootState) => state.config.status;

export default configSlice.reducer;
