import { createSlice } from '@reduxjs/toolkit';
import { IHomeAdminState } from './models';
import { getSummaryAsync } from './thunks';
import { RootState } from '../../store';

export const initialState: IHomeAdminState = {
  summary: null,
  status: 'idle',
  error: null,
};

const homeAdminSlice = createSlice({
  name: 'homeAdmin',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSummaryAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getSummaryAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.summary = action.payload.data;
      })
      .addCase(getSummaryAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export const selectedHomeAdmin = (state: RootState) => state.homeAdmin.summary;
export const getStatusFetchHomeAdmin = (state: RootState) => state.homeAdmin.status;
export const getHomeAdminState = (state: RootState) => state.homeAdmin;

export default homeAdminSlice.reducer;
