import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { initialPages } from '../../utils/constants/pagination';
import { ICustomerBillState } from './models';
import { editBillAsync, fetchBillsByCompanyAsync, fetchAllBillsAsync } from './thunks';
import { toastSuccessMessage } from '../../utils/toast';

export const initialState: ICustomerBillState = {
  status: 'idle',
  bills: [],
  pages: initialPages,
};

const customerBillSlice = createSlice({
  name: 'customerBill',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBillsByCompanyAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchBillsByCompanyAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.bills = action.payload?.data?.list;
        state.pages = action.payload?.data?.pages;
      })
      .addCase(fetchBillsByCompanyAsync.rejected, (state) => {
        state.status = 'failed';
        state.bills = [];
      })

      .addCase(editBillAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editBillAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.bills = action.payload.data.list;
        toastSuccessMessage('Cambios guardados');
      })
      .addCase(editBillAsync.rejected, (state) => {
        state.status = 'failed';
        state.bills = [];
      })
      .addCase(fetchAllBillsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllBillsAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.bills = action.payload.data;
      })
      .addCase(fetchAllBillsAsync.rejected, (state) => {
        state.status = 'failed';
        state.bills = [];
      });
  },
});
export const getCustomerBillState = (state: RootState) => state.customerBill;
export default customerBillSlice.reducer;
