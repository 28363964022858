import URL from './url';
import { ICreateService } from '../../models/IService';
import { IServicesListResponse, IServiceResponse, IServiceUpdatedResponse } from './models';

export const createService = async (serviceData: ICreateService, token: string): Promise<IServiceUpdatedResponse> => {
  const { url, params } = URL.createService(serviceData, token);
  const response = await fetch(url, params);
  return await response.json();
};

export const getService = async (serviceId: string, token: string): Promise<IServiceResponse> => {
  const { url, params } = URL.getService(serviceId, token);
  const response = await fetch(url, params);
  return await response.json();
};

export const editService = async (
  serviceId: string,
  updatedData: ICreateService,
  token: string
): Promise<IServiceUpdatedResponse> => {
  const { url, params } = URL.editService(serviceId, updatedData, token);
  const response = await fetch(url, params);
  return await response.json();
};

export const getServices = async (token: string): Promise<IServicesListResponse> => {
  const { url, params } = URL.getServices(token);
  const response = await fetch(url, params);
  return await response.json();
};

export const deleteService = async (serviceId: string, token: string): Promise<IServicesListResponse> => {
  const { url, params } = URL.deleteService(serviceId, token);
  const response = await fetch(url, params);
  return await response.json();
};
