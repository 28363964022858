import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ISkillGroup } from '../../models/ISkill';
import { RootState } from '../../store';
import { toastSuccessMessage } from '../../utils/toast';
import { createJobOfferAsync } from '../jobOffersSlice/thunks';
import { ICustomerSkillsGroupState } from './models';
import {
  createCustomerSkillsGroupAsync,
  deleteCustomerSkillGroupAsync,
  deleteCustomerTemplateSkillGroupAsync,
  editCustomerSkillGroupAsync,
  getCustomerSkillGroupsAsync,
  getCustomerTemplateSkillGroupsAsync,
  getOneCustomerSkillGroupAsync,
} from './thunks';

export const initialState: ICustomerSkillsGroupState = {
  skillsGroup: [],
  selectedSkillsGroup: null,
  template: [],
  status: 'idle',
  error: null,
  newJobOfferSkills: [],
};

const CustomerSkillsGroupSlice = createSlice({
  name: 'customerSkillsGroup',
  initialState,
  reducers: {
    setSelectedSkillsGroup: (state, action: PayloadAction<ISkillGroup>) => {
      state.selectedSkillsGroup = action.payload;
    },
    addSkilltoJobOffer: (state) => {
      const skillId = state.selectedSkillsGroup?._id ?? state.skillsGroup.at(0)?._id;
      if (skillId) state.newJobOfferSkills = [...new Set([skillId, ...state.newJobOfferSkills])];
    },
    setNewJobOfferSkills: (state, action: PayloadAction<ICustomerSkillsGroupState['newJobOfferSkills']>) => {
      state.newJobOfferSkills = action.payload;
    },
    onJobOfferClose: (state) => {
      state.newJobOfferSkills = [];
    },
  },
  extraReducers: (builder) => {
    builder
      //createCustomerSkillsGroupAsync
      .addCase(createCustomerSkillsGroupAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createCustomerSkillsGroupAsync.fulfilled, (state, action) => {
        const { created, list } = action.payload.data;
        state.skillsGroup = list;
        state.status = 'success';
        state.newJobOfferSkills = [created._id, ...state.newJobOfferSkills];
        toastSuccessMessage('Grupo de competencias creado con éxito');
      })
      .addCase(createCustomerSkillsGroupAsync.rejected, (state) => {
        state.status = 'failed';
      })
      //getCustomerSkillGroupsAsync
      .addCase(getCustomerSkillGroupsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCustomerSkillGroupsAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.skillsGroup = action.payload.data;
      })
      .addCase(getCustomerSkillGroupsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      //getOneCustomerSkillGroupAsync
      .addCase(getOneCustomerSkillGroupAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getOneCustomerSkillGroupAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.selectedSkillsGroup = action.payload.data;
      })
      .addCase(getOneCustomerSkillGroupAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      //editCustomerSkillGroupAsync
      .addCase(editCustomerSkillGroupAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editCustomerSkillGroupAsync.fulfilled, (state, action) => {
        state.skillsGroup = action.payload.data.list;
        state.status = 'success';
        toastSuccessMessage('Grupo de competencias editado con éxito');
      })
      .addCase(editCustomerSkillGroupAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      //deleteCustomerSkillGroupAsync
      .addCase(deleteCustomerSkillGroupAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteCustomerSkillGroupAsync.fulfilled, (state, action) => {
        state.skillsGroup = action.payload.data;
        state.status = 'success';
        toastSuccessMessage('Grupo de competencias eliminado con éxito');
      })
      .addCase(deleteCustomerSkillGroupAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      //getCustomerTemplateSkillGroupsAsync
      .addCase(getCustomerTemplateSkillGroupsAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCustomerTemplateSkillGroupsAsync.fulfilled, (state, action) => {
        state.status = 'success';
        state.template = action.payload.data;
      })
      .addCase(getCustomerTemplateSkillGroupsAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      //deleteCustomerTemplateSkillGroupAsync
      .addCase(deleteCustomerTemplateSkillGroupAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteCustomerTemplateSkillGroupAsync.fulfilled, (state, action) => {
        state.template = action.payload.data;
        state.status = 'success';
        toastSuccessMessage('Plantilla de grupo de competencias eliminado con éxito');
      })
      .addCase(deleteCustomerTemplateSkillGroupAsync.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })

      //createJobOfferAsync
      .addCase(createJobOfferAsync.fulfilled, (state) => {
        state.newJobOfferSkills = [];
      });
  },
});

export const { setSelectedSkillsGroup, onJobOfferClose, addSkilltoJobOffer, setNewJobOfferSkills } =
  CustomerSkillsGroupSlice.actions;
export const getSkillGroupState = (state: RootState) => state.customerSkillsGroup;
export const getSkillGroupStatus = (state: RootState) => state.customerSkillsGroup.status;
export const getSelectedSkillsGroup = (state: RootState) => state.customerSkillsGroup.selectedSkillsGroup;

export default CustomerSkillsGroupSlice.reducer;
